import React, { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { IPersonalInfo } from '@/@types/profile';
import Container from '@/components/Container';
import ReactDropdown from '@/components/Dropdown';
import FileDropContainer from '@/components/FileDropContainer';
import FormField from '@/components/FormField';
import ProfileBadge from '@/components/ProfileBadge';
import { Typography } from '@/components/Typography';
import { Button } from '@/components/ui/button';
import { PERSONAL_INFO_FIELDS } from '@/constants/profile';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import { useFormContext } from '@/provider/FormProvider/index';
import { personalInfoSchema } from '@/schema/profile';
import { zodResolver } from '@hookform/resolvers/zod';

const PersonalInfo = () => {
  const { profile: staticText, common } = strings;

  const { stepOne, setData, handleNext, handleBack, handleSkip } =
    useFormContext();

  const [previewUrl, setPreviewUrl] = useState<string | null>(null);

  const {
    control,
    formState: { errors },
    watch,
    handleSubmit,
  } = useForm<IPersonalInfo>({
    mode: 'all',
    resolver: zodResolver(personalInfoSchema),
    defaultValues: stepOne || {},
  });

  const avatar = watch('avatar');
  const resume = watch('resume');

  useEffect(() => {
    if (avatar) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result as string);
      };
      reader.readAsDataURL(avatar as Blob);
    } else {
      setPreviewUrl(null);
    }
  }, [avatar]);

  const onSubmit: SubmitHandler<IPersonalInfo> = (data) => {
    // TODO: API call
    setData({ step: 1, data });
    handleNext();
  };

  return (
    <>
      <Container className='rounded-t-none border-t-0 p-4 w-[95%] mx-auto'>
        <div className='mt-10 md:mt-20'>
          <Typography variant='heading' className='md:text-2xl'>
            {staticText.personalInformation}
          </Typography>

          <div className='md:grid md:grid-cols-3 gap-x-4 mt-4'>
            <div className='flex gap-3 items-center flex-col md:flex-row'>
              <ProfileBadge
                name={''}
                profilePicture={previewUrl || ''}
                avatarClassName='size-24'
              />
              <Controller
                name='avatar'
                control={control}
                render={({ field: { onChange } }) => (
                  <FileDropContainer
                    setFile={onChange}
                    fileName={typeof avatar === 'object' ? avatar?.name : ''}
                    dropzoneClassName='w-full h-28 p-2'
                    uploadingDivClassName='w-full h-28'
                    acceptedFileFormat={['.png', '.jpeg']}
                  />
                )}
              />
            </div>
            <div className='col-span-2'>
              <Controller
                name='resume'
                control={control}
                render={({ field: { onChange } }) => (
                  <FileDropContainer
                    buttonText={staticText.uploadResume}
                    setFile={onChange}
                    fileName={typeof resume === 'object' ? resume?.name : ''}
                    dropzoneClassName='w-full h-28 p-2'
                    uploadingDivClassName='w-full h-28'
                    iconClassName='bg-primary rounded-full text-white text-[40px] p-2'
                    acceptedFileFormat={['.pdf', '.docx']}
                    isResume
                  />
                )}
              />
            </div>
            {PERSONAL_INFO_FIELDS.map((field, index) => (
              <React.Fragment key={`${field.name}_${index}`}>
                <div
                  className={cn({
                    [`col-span-${field.colSpan}`]: field.colSpan,
                  })}
                >
                  <Controller
                    control={control}
                    name={field.name as keyof IPersonalInfo}
                    render={({ field: { onChange, value, name } }) =>
                      field.isDropdown ? (
                        <ReactDropdown
                          name={name}
                          title={field.title}
                          placeholder={common.typeHere}
                          options={field.dropdownOptions}
                          value={field.dropdownOptions.find(
                            (option) => option.value === value,
                          )}
                          onChange={onChange}
                          className='w-full mt-2 h-12 rounded-md'
                          labelClassName='font-bold md:text-base mb-3'
                        />
                      ) : (
                        <FormField
                          title={field.title}
                          placeholder={common.typeHere}
                          name={name}
                          value={value as keyof IPersonalInfo}
                          onChange={onChange}
                          errors={errors}
                          labelClassName='font-bold md:text-base mb-3'
                        />
                      )
                    }
                  />
                </div>
                {field.isTwo && <div />}
              </React.Fragment>
            ))}
          </div>
        </div>
      </Container>
      <div className='w-[95%] mx-auto py-3 flex justify-between'>
        <div onClick={() => handleSkip(1)}>
          <Typography className='text-primary underline font-semibold cursor-pointer'>
            {staticText.buttons.skip}
          </Typography>
        </div>
        <div className='flex gap-3'>
          <Button
            variant='outline'
            className='md:p-3 lg:p-5 focus-visible:ring-0 sm:w-44 md:w-32 font-medium sm:text-base !ring-offset-0'
            onClick={handleBack}
            disabled
          >
            {staticText.buttons.back}
          </Button>
          <Button className='sm:w-44 md:w-32' onClick={handleSubmit(onSubmit)}>
            {staticText.buttons.next}
          </Button>
        </div>
      </div>
    </>
  );
};

export default PersonalInfo;
