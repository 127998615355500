import React from 'react';

import ProfileStatus from './components/ProfileStatus';
// import { Button } from '@/components/ui/button';
// import { strings } from '@/locales';
// import ContactInfoModal from './components/ContactInfoModal';
// import ReferFriendModal from './components/ReferFriendModal';
// import UploadFileModal from './UploadFileModal';

const Dashboard = () => {
  // const [isReferModalOpen, setIsReferModalOpen] = useState(false);
  // const handleSubmit = () => {};
  // const staticText = strings.dashboard;
  // const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  // const [openUploadFileModal, setUploadFileModal] = useState(false);

  return (
    <div>
      <ProfileStatus />
      {/* <Button onClick={() => setIsReferModalOpen(true)}>
        {staticText.openReferFriendModal}
      </Button>
      <ReferFriendModal
        handleClose={() => setIsReferModalOpen(false)}
        open={isReferModalOpen}
      />
      <Button onClick={() => setIsContactModalOpen(true)}>
        {staticText.openContactInfoModal}
      </Button>
      <ContactInfoModal
        open={isContactModalOpen}
        handleClose={() => setIsContactModalOpen(false)}
        onSubmit={handleSubmit}
      />
      <Button onClick={() => setUploadFileModal(true)}>
        {staticText.uploadFile}
      </Button>
      {openUploadFileModal ? (
        <UploadFileModal
          open={openUploadFileModal}
          handleCloseModal={() => setUploadFileModal(false)}
        />
      ) : null} */}
    </div>
  );
};

export default Dashboard;
