import { strings } from '@/locales';

export const PASSWORD_VALIDATIONS_POINTS = [
  strings.passwordValidationText.charLength,
  strings.passwordValidationText.lowerCase,
  strings.passwordValidationText.upperCase,
  strings.passwordValidationText.number,
  strings.passwordValidationText.specialChar,
];

export const enum USER_PROVIDER {
  EMAIL = 'EMAIL',
}
export const COMMON_ERROR = 'Something went wrong!';
export const COMMON_SUCCESS = 'Operation completed successfully!';

export enum USER_TYPE {
  CANDIDATE = 'CANDIDATE',
}

export const INPUT_ERRORS = {
  EMAIL: {
    required: '*Email is required.',
    pattern: '*Enter a valid email.',
  },
  PASSWORD: {
    required: strings.passwordValidationText.required,
    minLength: strings.passwordValidationText.charLength,
    lowercase: strings.passwordValidationText.lowerCase,
    uppercase: strings.passwordValidationText.upperCase,
    number: strings.passwordValidationText.number,
    specialChar: strings.passwordValidationText.specialChar,
  },
  CONFIRM_PASSWORD: {
    required: '*Confirm Password is required.',
    match: '*Passwords do not match',
  },
  FIRST_NAME: {
    required: '*Full Name is required.',
  },
  LAST_NAME: {
    required: '*Full Name is required.',
  },
  NAME: {
    required: '*Name is required.',
  },
  E_MAIL: {
    required: '*Email is required.',
  },
  PHONE_NUMBER: {
    required: '*Phone Number is required.',
  },
  PRESENT_ADDRESS: {
    required: '*Present Address is required.',
  },
  INVALID_EMAIL: {
    required: 'Invlid Email Format',
  },
  INVALID_PHONE_NUMBER: {
    required: 'Invlid Phone Number Format',
  },
  COMPANY_NAME: {
    required: '*Company Name is required.',
  },
  STATUS: {
    required: '*Status is required.',
  },
  TAGS: {
    required: '*Tag is required.',
  },
  JOB_POSITION: {
    required: '*Job Position is required.',
  },
  DEPARTMENT: {
    required: '*Department is required.',
  },
  NOTES: {
    required: '*Notes required.',
  },
  FILE_SIZE: {
    maxFileSize: '*File size exceeds 500 KB',
  },
  TICKET: {
    required: '*Ticket Name is required.',
  },
  FILE: {
    required: '*File is required.',
  },
};

export enum LOCAL_CONSTANT {
  USER_TOKEN = 'token',
  USER = 'user',
}
export enum HEADER_DROPDOWN_OPTIONS {
  PROFILE = 'PROFILE',
  MY_DOCUMENTS = 'MY_DOCUMENTS',
  LOGOUT = 'LOGOUT',
}

export const PROFILE_DROPDOWN_OPTIONS = [
  { value: HEADER_DROPDOWN_OPTIONS.PROFILE, label: 'Profile' },
  { value: HEADER_DROPDOWN_OPTIONS.MY_DOCUMENTS, label: 'My Documents' },
  { value: HEADER_DROPDOWN_OPTIONS.LOGOUT, label: 'Logout' },
];
