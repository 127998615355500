import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { cn } from '@/lib/utils';
import { useAuth } from '@/provider/AuthProvider';

import { ROUTES } from '.';

interface ProtectedRouteProps {
  element: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element }) => {
  const { token, isCandidate } = useAuth();
  const location = useLocation();
  const redirectPath = location.pathname;
  const isSmallScreen = window.innerWidth < 768;

  const isNotificationScreen =
    isSmallScreen && location.pathname.includes(ROUTES.NOTIFICATIONS);

  if (!token) {
    return <Navigate to={ROUTES.LOGIN} state={{ from: redirectPath }} />;
  }

  if (token && isCandidate) {
    const redirectTo =
      location.state?.from && location.state.from !== ROUTES.LOGIN
        ? location.state.from
        : redirectPath || ROUTES.DASHBOARD;
    if (redirectTo !== redirectPath) {
      return <Navigate to={redirectTo} />;
    }
  }
  return (
    <div
      className={cn('p-5 md:p-6 flex-1 overflow-auto', {
        'p-0': isNotificationScreen,
      })}
    >
      {element}
    </div>
  );
};

export default ProtectedRoute;
