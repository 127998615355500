import React, { useRef } from 'react';
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { FaPlus } from 'react-icons/fa6';
import { HiOutlineTrash } from 'react-icons/hi';

import { IFinalStep } from '@/@types/profile';
import Container from '@/components/Container';
import FormField from '@/components/FormField';
import { Typography } from '@/components/Typography';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';
import { Textarea } from '@/components/ui/textarea';
import {
  EMERGENCY_CONTACT_FIELDS,
  WORKING_RELATIVES_FIELDS,
} from '@/constants/profile';
import { strings } from '@/locales';
import { useFormContext } from '@/provider/FormProvider/index';
import { finalStepSchema } from '@/schema/profile';
import { zodResolver } from '@hookform/resolvers/zod';

const FinalStep = () => {
  const { profile: staticText, common } = strings;

  const { stepSix, setData, handleNext, handleBack, handleSkip } =
    useFormContext();

  const newRelativeRef = useRef<HTMLDivElement | null>(null);
  const newEmergencyContactRef = useRef<HTMLDivElement | null>(null);

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<IFinalStep>({
    mode: 'all',
    resolver: zodResolver(finalStepSchema),
    defaultValues: stepSix || {},
  });

  const { fields, append, remove } = useFieldArray<
    IFinalStep,
    'relation',
    'id'
  >({
    control,
    name: 'relation',
  });

  const {
    fields: emergencyFields,
    append: appendEmergency,
    remove: removeEmergency,
  } = useFieldArray<IFinalStep, 'emergency', 'id'>({
    control,
    name: 'emergency',
  });

  const addNewRelative = () => {
    append({
      name: '',
      relationship: '',
    });
    setTimeout(() => {
      if (newRelativeRef.current) {
        newRelativeRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }, 100);
  };

  const removeRelative = (index: number) => {
    remove(index);
  };

  const addEmergencyContact = () => {
    appendEmergency({
      name: '',
      relationship: '',
      address: '',
      telNo: '',
    });
    setTimeout(() => {
      if (newEmergencyContactRef.current) {
        newEmergencyContactRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }, 100);
  };

  const removeEmergencyContact = (index: number) => {
    removeEmergency(index);
  };

  const onSubmit: SubmitHandler<IFinalStep> = (data) => {
    // TODO: API call
    setData({ step: 6, data });
    handleNext();
  };

  return (
    <form>
      <Container className='rounded-t-none border-t-0 p-4 w-[95%] mx-auto'>
        <div className='mt-10 md:mt-20'>
          <div className='md:flex justify-between'>
            <Typography
              variant='heading'
              className='text-lg md:text-2xl capitalize'
            >
              {staticText.relativeWorkingWithSmoothMovesQuestion}
            </Typography>
            <div
              onClick={addNewRelative}
              className='float-right w-44 flex justify-end md:float-none cursor-pointer'
            >
              <Typography className='text-primary font-semibold flex items-center gap-1'>
                <FaPlus />
                {staticText.buttons.addNew}
              </Typography>
            </div>
          </div>
          {fields.map((field, sectionIndex) => (
            <div
              key={field.id}
              id={`section-${sectionIndex}`}
              ref={newRelativeRef}
            >
              {sectionIndex !== 0 ? (
                <>
                  <Separator className='mt-5 mb-2' />
                  <div className='flex justify-end'>
                    <div onClick={() => removeRelative(sectionIndex)}>
                      <Typography className='text-tomatoRed mb-1 font-semibold cursor-pointer flex items-center gap-1'>
                        <HiOutlineTrash />
                        {staticText.buttons.delete}
                      </Typography>
                    </div>
                  </div>
                </>
              ) : null}
              <div className='md:grid md:grid-cols-3 gap-x-4 mt-4'>
                {WORKING_RELATIVES_FIELDS.map((fieldConfig, index) => (
                  <div key={`${fieldConfig.name}_${index}`}>
                    <Controller
                      control={control}
                      name={
                        `relation.${sectionIndex}.${fieldConfig.name}` as keyof IFinalStep
                      }
                      render={({ field: { onChange, value, name } }) => (
                        <FormField
                          title={fieldConfig.title}
                          placeholder={common.typeHere}
                          name={name}
                          value={value as unknown as string | number}
                          onChange={onChange}
                          errors={errors}
                          labelClassName='font-bold md:text-base mb-3'
                        />
                      )}
                    />
                  </div>
                ))}
              </div>
            </div>
          ))}
          <div className='my-6'>
            <Typography
              variant='heading'
              className='text-lg md:text-2xl capitalize'
            >
              {staticText.chargedInCourt}
            </Typography>
          </div>
          <Typography className='flex capitalize font-bold md:text-base mb-3'>
            {staticText.name}
          </Typography>
          <Controller
            control={control}
            name='chargedCourt'
            render={({ field: { onChange, value } }) => (
              <Textarea
                rows={4}
                value={value as string}
                onChange={onChange}
                errors={errors}
                placeholder={common.typeHere}
                className='border-greyWhite rounded-xl resize-none w-full placeholder:text-quickSilver placeholder:text-sm text-sm md:text-sm font-medium min-h-10 text-primaryBlack max-h-40 mt-1 p-2'
              />
            )}
          />
          <div className='my-6 md:flex justify-between'>
            <Typography
              variant='heading'
              className='text-lg md:text-2xl capitalize'
            >
              {staticText.emergencyContact}
            </Typography>
            <div onClick={addEmergencyContact} className='flex justify-end'>
              <Typography className='text-primary font-semibold cursor-pointer flex items-center gap-1'>
                <FaPlus />
                {staticText.buttons.addNew}
              </Typography>
            </div>
          </div>
          {emergencyFields.map((field, sectionIndex) => (
            <div
              key={field.id}
              id={`section-${sectionIndex}`}
              ref={newEmergencyContactRef}
            >
              {sectionIndex !== 0 ? (
                <>
                  <Separator className='mt-5 mb-2' />
                  <div className='flex justify-end'>
                    <div onClick={() => removeEmergencyContact(sectionIndex)}>
                      <Typography className='text-tomatoRed mb-1 font-semibold cursor-pointer flex items-center gap-1'>
                        <HiOutlineTrash />
                        {staticText.buttons.delete}
                      </Typography>
                    </div>
                  </div>
                </>
              ) : null}
              <div className='md:grid md:grid-cols-3 gap-x-4 mt-4'>
                {EMERGENCY_CONTACT_FIELDS.map((fieldConfig, index) => (
                  <div key={`${fieldConfig.name}_${index}`}>
                    <Controller
                      control={control}
                      name={
                        `relation.${sectionIndex}.${fieldConfig.name}` as keyof IFinalStep
                      }
                      render={({ field: { onChange, value, name } }) => (
                        <FormField
                          title={fieldConfig.title}
                          placeholder={common.typeHere}
                          name={name}
                          value={value as unknown as string | number}
                          onChange={onChange}
                          errors={errors}
                          labelClassName='font-bold md:text-base mb-3'
                        />
                      )}
                    />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </Container>
      <div className='w-[95%] mx-auto py-3 flex justify-between'>
        <div onClick={() => handleSkip(6)}>
          <Typography className='text-primary underline font-semibold cursor-pointer'>
            {staticText.buttons.skip}
          </Typography>
        </div>
        <div className='flex gap-3'>
          <Button
            variant='outline'
            className='md:p-3 lg:p-5 focus-visible:ring-0 sm:w-44 md:w-32 font-medium sm:text-base !ring-offset-0'
            onClick={handleBack}
          >
            {staticText.buttons.back}
          </Button>
          <Button className='sm:w-44 md:w-32' onClick={handleSubmit(onSubmit)}>
            {staticText.buttons.submit}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default FinalStep;
