import React, { useEffect, useRef, useState } from 'react';
import { FieldErrors } from 'react-hook-form';
import { FaChevronDown } from 'react-icons/fa6';
import {
  components,
  GroupBase,
  OptionProps as ReactSelectOptionProps,
  OptionsOrGroups,
  SingleValueProps,
} from 'react-select';
import { AsyncPaginate, LoadOptions } from 'react-select-async-paginate';

import { IDropdownOption } from '@/@types';
import { cn } from '@/lib/utils';
import { getTagVariant } from '@/utils/common';
import { ErrorMessage as HookFormErrorMessage } from '@hookform/error-message';

import { CustomStyles, customStyles } from './customStyle';
import ProfileBadge from './ProfileBadge';
import Tags from './Tags';
import { Typography } from './Typography';

export interface DropdownProps {
  title?: string;
  options: IDropdownOption[] | string[];
  defaultValue?: string | IDropdownOption;
  value?: IDropdownOption | null | string;
  placeholder?: string;
  isLoading?: boolean;
  isSearchable?: boolean;
  isDisabled?: boolean;
  isRequired?: boolean;
  labelClassName?: string;
  isClearable?: boolean;
  onChange?: (selectedOption: string | IDropdownOption) => void;
  // onChange?: (selectedOption: string | IDropdownOption) => void;
  className?: string;
  name?: string;
  setPage?: React.Dispatch<React.SetStateAction<number>>;
  haveMoreOptions?: boolean;
  inputHeight?: string;
  inputWidth?: string;
  valueHide?: boolean;
  tagsDropdown?: boolean;
  errors?: FieldErrors;
  borderRadius?: string;
  smallDropdown?: boolean;
  icon?: React.ReactNode;
  setInputSearch?: React.Dispatch<React.SetStateAction<string>>;
  saveBothLabelAndValue?: boolean;
  customButton?: React.ReactNode;
}

const ReactDropdown: React.FC<DropdownProps> = ({
  title,
  defaultValue,
  options,
  value: propValue = null,
  placeholder,
  isSearchable = false,
  isDisabled = false,
  isLoading = false,
  isRequired = false,
  labelClassName,
  isClearable = false,
  name,
  className,
  onChange,
  haveMoreOptions = false,
  setPage,
  inputHeight = '48px',
  inputWidth = '100%',
  valueHide = false,
  smallDropdown = false,
  tagsDropdown = false,
  borderRadius = '6px',
  errors,
  setInputSearch,
  saveBothLabelAndValue = false,
  customButton,
}) => {
  // if string array in options then convert it to value label object
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const normalizedOptions: IDropdownOption[] = Array.isArray(options)
    ? options.map((opt) =>
        typeof opt === 'string' ? { label: opt, value: opt } : opt,
      )
    : [];
  // for showing placeholder if value is empty string
  const convertedValue =
    typeof propValue === 'string'
      ? (normalizedOptions.find((option) => option.value === propValue) ?? null)
      : propValue;

  const customStylesWithHeight: CustomStyles = {
    ...customStyles,
    control: (provided) => {
      const borderColor = customButton
        ? 'transparent' // Always transparent if customButton is present
        : '#E4E6E9'; // Use '#E4E6E9' when not focused and no customButton

      return {
        ...provided,
        'minHeight': inputHeight,
        'maxHeight': inputHeight,
        'minWidth': inputWidth,
        'borderColor': borderColor,
        'boxShadow': undefined,
        '&:hover': {
          borderColor: borderColor,
        },
        'borderRadius': borderRadius,
      };
    },
    option: (provided) => ({
      ...provided,
      cursor: 'pointer',
    }),
  };

  const getSelectValue = () => {
    switch (true) {
      case valueHide:
        return '';
      case !convertedValue?.value:
        return null;
      default:
        return convertedValue;
    }
  };

  const CustomOption: React.FC<
    ReactSelectOptionProps<IDropdownOption | string>
  > = (props) => {
    const renderOptionContent = () => {
      if (tagsDropdown) {
        return (
          <Tags
            text={typeof props === 'object' ? props.label : props}
            variant={getTagVariant(props.label)}
            containerClassName='py-1 text-xs h-auto min-w-20 text-sm'
          />
        );
      }
      if (
        typeof options[0] === 'object' &&
        'avatar' in (options[0] as IDropdownOption)
      ) {
        return (
          <ProfileBadge
            name={props.label}
            className='justify-start'
            avatarClassName='size-7 text-[10px]'
            profilePicture={(props.data as IDropdownOption)?.avatar}
          />
        );
      }
      return props.label;
    };
    return (
      <div>
        <components.Option {...props}>
          {renderOptionContent()}
        </components.Option>
      </div>
    );
  };

  const SingleValue = ({
    children,
    ...props
  }: SingleValueProps<IDropdownOption | string>) => {
    let content;
    if (tagsDropdown) {
      content = (
        <Tags
          text={convertedValue?.label}
          variant={getTagVariant(convertedValue?.label)}
          containerClassName='py-1 text-xs h-auto min-w-20 text-sm mx-5'
        />
      );
    } else if ((convertedValue as IDropdownOption).avatar) {
      content = (
        <ProfileBadge
          name={convertedValue?.label || ''}
          className='justify-start'
          profilePicture={convertedValue?.avatar}
          avatarClassName='size-7 text-[10px]'
        />
      );
    } else if ((convertedValue as IDropdownOption).icon) {
      content = (
        <div className='flex items-center'>
          {(convertedValue as IDropdownOption).icon}
          <span className='ml-2'>{convertedValue?.label}</span>
        </div>
      );
    } else {
      content = children;
    }
    return (
      <components.SingleValue {...props}>{content}</components.SingleValue>
    );
  };

  const onChangeValue = (selected: IDropdownOption) => {
    if (onChange) {
      onChange(saveBothLabelAndValue ? selected : selected?.value);
    }
    setIsOpen(false);
  };

  const loadMore = () => {
    if (haveMoreOptions) {
      setPage?.((prev) => prev + 1);
    }
  };

  const handleInputChange = (input: string) => {
    setInputSearch?.(input);
  };

  const loadOptions: LoadOptions<
    string | IDropdownOption,
    GroupBase<string | IDropdownOption>,
    unknown
  > = (
    _search: string,
    prevOptions: OptionsOrGroups<
      string | IDropdownOption,
      GroupBase<string | IDropdownOption>
    >,
  ) => {
    const filteredOptions = normalizedOptions
      .slice(prevOptions.length, prevOptions.length + 10)
      .map((option) =>
        typeof option === 'string'
          ? { label: option, value: option }
          : { label: option.label, value: option.value, icon: option.icon },
      );

    return {
      options: filteredOptions as OptionsOrGroups<
        string | IDropdownOption,
        GroupBase<string | IDropdownOption>
      >,
      hasMore: true,
    };
  };

  return (
    <div ref={dropdownRef}>
      {title ? (
        <Typography
          className={cn(
            'flex md:text-sm capitalize font-semibold my-2',
            labelClassName,
          )}
        >
          {title}
          {isRequired ? (
            <span className='text-redColor text-xl ml-1'>*</span>
          ) : null}
        </Typography>
      ) : null}
      <div className={cn('relative', className)}>
        {customButton ? (
          <div onClick={toggleDropdown}>{customButton}</div>
        ) : null}
        <div
          className={cn('relative', { 'absolute z-10 w-full': customButton })}
        >
          <AsyncPaginate
            controlShouldRenderValue={true}
            placeholder={!customButton ? placeholder : ''}
            classNamePrefix='select'
            value={getSelectValue() as IDropdownOption}
            isDisabled={isDisabled}
            isLoading={isLoading}
            isClearable={isClearable}
            isSearchable={isSearchable}
            menuPlacement='auto'
            defaultValue={
              typeof defaultValue === 'string'
                ? { label: defaultValue, value: defaultValue }
                : defaultValue
            }
            name={name}
            onChange={onChangeValue as never}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            styles={customStylesWithHeight as CustomStyles | any}
            loadOptions={loadOptions}
            onMenuScrollToBottom={loadMore}
            maxMenuHeight={150}
            onInputChange={handleInputChange}
            components={{
              SingleValue,
              Option: CustomOption,
            }}
            autoFocus={false}
            menuIsOpen={customButton ? isOpen : undefined}
            onMenuClose={() => setIsOpen(false)}
          />
        </div>
        {!customButton ? (
          <FaChevronDown
            className={cn(
              'ml-2 size-4 shrink-0 opacity-50 absolute right-4 top-1/2 -translate-y-1/2',
              { 'right-2 top-1/3 size-3 -translate-y-0': smallDropdown },
            )}
          />
        ) : null}
      </div>
      {errors && (
        <HookFormErrorMessage
          errors={errors}
          name={String(name)}
          render={({ message }) => (
            <p className='text-redColor text-xs'>{message}</p>
          )}
        />
      )}
    </div>
  );
};

export default ReactDropdown;
