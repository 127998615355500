import React from 'react';
import { GoLinkExternal } from 'react-icons/go';
import { useNavigate } from 'react-router-dom';

import Container from '@/components/Container';
import ProfileAvatar from '@/components/ProfileAvatar';
import { Typography } from '@/components/Typography';
import { Button } from '@/components/ui/button';
import { strings } from '@/locales';
import { useAuth } from '@/provider/AuthProvider';
import { ROUTES } from '@/routes';

import Vector from '../../../assets/images/Vector.png';

import ProfileSections from './ProfileSections';
import ProgressBar from './ProgressBar';

const ProfileStatus = () => {
  const staticText = strings.profileStatusScreen;
  const { user } = useAuth();
  const { lastName, firstName } = user || {};
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(ROUTES.PROFILE);
  };

  return (
    <Container className='w-full lg:w-70 h-fit relative bg-gradient-to-r from-seaBlue to-seaBlueDark p-4'>
      <img
        src={Vector}
        className='absolute bottom-0 left-0 z-0 w-full'
        alt='Background'
      />
      <div className='flex flex-col sm:flex-row justify-between items-start w-full relative z-10'>
        <div className='flex items-center gap-3 md:w-1/3 mb-4 sm:mb-0'>
          <ProfileAvatar
            name={`${firstName} ${lastName}`}
            src='/path/to/profile-picture.jpg'
            className='md:size-16 size-11'
          />
          <div className='flex flex-col'>
            <Typography className='md:text-sm text-white'>
              {staticText.welcome}
            </Typography>
            <Typography className='sm:leading-4 text-white md:mt-0 md:text-xl lg:text-2xl font-bold'>
              {`${firstName} ${lastName}`}
            </Typography>
          </div>
        </div>
        <div className='w-full z-50 md:w-3/5 sm:mr-14 md:mr-24 text-center'>
          <Typography className='text-white'>{staticText.message}</Typography>
          {/* TODO: Pass progress values from API */}
          <ProgressBar progress={50} />
        </div>
        <Button
          variant='outline'
          size='icon'
          className='sm:size-9 size-5 p-0 rounded border border-greyWhite absolute top-0 right-0 '
          onClick={handleNavigate}
        >
          <GoLinkExternal className='size-4 sm:size-6 text-primary' />
        </Button>
      </div>
      <ProfileSections />
    </Container>
  );
};

export default ProfileStatus;
