import React from 'react';

import { cn } from '@/lib/utils';

import CircularLoader from './ClipLoader';

interface Props {
  width?: string;
  height?: string;
  className?: string;
  loading?: boolean;
  children: React.ReactNode;
}

const Container: React.FC<Props> = ({
  width = '',
  height = '',
  className,
  loading,
  children,
}) => {
  return (
    <div
      className={cn(
        'bg-white rounded-xl px-2 md:px-4 md:py-5 py-3 border border-greyWhite scrollbarHidden',
        width,
        height,
        className,
        { 'flex justify-center items-center h-[calc(100vh-200px)]': loading },
      )}
    >
      {loading ? <CircularLoader /> : children}
    </div>
  );
};

export default Container;
