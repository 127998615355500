import React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { IAcademicHistory } from '@/@types/profile';
import Container from '@/components/Container';
import FormField from '@/components/FormField';
import { Typography } from '@/components/Typography';
import { Button } from '@/components/ui/button';
import { ACADEMIC_HISTORY_FIELDS } from '@/constants/profile';
import { strings } from '@/locales';
import { useFormContext } from '@/provider/FormProvider/index';
import { academicHistorySchema } from '@/schema/profile';
import { zodResolver } from '@hookform/resolvers/zod';

const AcademicHistory = () => {
  const { profile: staticText, common } = strings;

  const { stepTwo, setData, handleNext, handleBack, handleSkip } =
    useFormContext();

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<IAcademicHistory>({
    mode: 'all',
    resolver: zodResolver(academicHistorySchema),
    defaultValues: stepTwo || {},
  });

  const onSubmit: SubmitHandler<IAcademicHistory> = (data) => {
    // TODO: API call
    setData({ step: 2, data });
    handleNext();
  };

  return (
    <form>
      <Container className='rounded-t-none border-t-0 p-4 w-[95%] mx-auto'>
        <div className='mt-10 md:mt-20'>
          <Typography variant='heading' className='md:text-2xl'>
            {staticText.academicHistory}
          </Typography>
          <div className='md:grid md:grid-cols-3 gap-x-4 mt-4'>
            {ACADEMIC_HISTORY_FIELDS.map((field, index) => (
              <div key={`${field.name}_${index}`}>
                <Controller
                  control={control}
                  name={field.name as keyof IAcademicHistory}
                  render={({ field: { onChange, value, name } }) => (
                    <FormField
                      title={field.title}
                      placeholder={common.typeHere}
                      name={name}
                      value={value as string | number}
                      onChange={onChange}
                      errors={errors}
                      labelClassName='font-bold md:text-base mb-3'
                    />
                  )}
                />
              </div>
            ))}
          </div>
        </div>
      </Container>
      <div className='w-[95%] mx-auto py-3 flex justify-between'>
        <div onClick={() => handleSkip(2)}>
          <Typography className='text-primary underline font-semibold cursor-pointer'>
            {staticText.buttons.skip}
          </Typography>
        </div>
        <div className='flex gap-3'>
          <Button
            variant='outline'
            className='md:p-3 lg:p-5 focus-visible:ring-0 sm:w-44 md:w-32 font-medium sm:text-base !ring-offset-0'
            onClick={handleBack}
          >
            {staticText.buttons.back}
          </Button>
          <Button className='sm:w-44 md:w-32' onClick={handleSubmit(onSubmit)}>
            {staticText.buttons.next}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default AcademicHistory;
