/* eslint-disable @typescript-eslint/no-explicit-any */
import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { COMMON_ERROR } from '@/constants';

import { IAPIResponse } from 'src/@types';
import { getAuthTokenFromLS } from 'src/utils/localstorage';

export const REST_URL = process.env.REACT_APP_BASE_URL || '';

// Check why multiple toasts shoeing on failing API
export const axiosInstance = Axios.create({
  baseURL: REST_URL,
});

export class API {
  static SerializeResponse<T>(
    response: AxiosResponse<T> | null,
    message: string,
  ): IAPIResponse<T> {
    const data = response as any;
    if (data.error) {
      throw new Error(message);
    }
    return data;
  }

  static async Post<RQ, RES>(
    url: string,
    payload: RQ,
    isFileOrImage?: boolean,
  ): Promise<IAPIResponse<RES>> {
    try {
      const headers = await this.getHeaders();
      if (isFileOrImage) {
        headers['Content-Type'] = 'multipart/form-data';
      }
      const res = await axiosInstance.post<RES>(url, payload, {
        headers,
      });
      const response = this.SerializeResponse<RES>(res, '');
      return {
        data: response?.data,
        status: true,
        message: response.message,
      };
    } catch (error: any) {
      return {
        data: null,
        status: false,
        message: error.response?.data?.message || COMMON_ERROR,
      };
    }
  }

  static async Get<RES>(url: string, config?: AxiosRequestConfig) {
    try {
      const headers = await this.getHeaders();
      const res = await axiosInstance.get<RES>(url, {
        headers,
        ...config,
      });
      const response = this.SerializeResponse<RES>(res, '');
      return {
        data: res instanceof Blob ? res : response?.data,
        status: true,
        message: '',
      };
    } catch (error: any) {
      return {
        data: null,
        status: false,
        message: error.response?.data?.error || COMMON_ERROR,
      };
    }
  }

  static async Put<RES, REQ = undefined>(
    url: string,
    payload?: REQ,
    isImage?: boolean,
  ) {
    try {
      const headers = await this.getHeaders();
      if (isImage) {
        headers['Content-Type'] = 'multipart/form-data';
      }
      const res = await axiosInstance.put<RES>(url, payload, {
        headers,
      });
      const response = this.SerializeResponse<RES>(res, '');
      return {
        data: response?.data,
        status: true,
        message: '',
      };
    } catch (error: any) {
      return {
        data: null,
        status: false,
        message: error.response?.data?.error || COMMON_ERROR,
      };
    }
  }

  static async Patch<RES, REQ = undefined>(
    url: string,
    payload?: REQ,
    isFileOrImage?: boolean,
  ) {
    try {
      const headers = await this.getHeaders();
      if (isFileOrImage) {
        headers['Content-Type'] = 'multipart/form-data';
      }
      const res = await axiosInstance.patch<RES>(url, payload, {
        headers,
      });
      const response = this.SerializeResponse<RES>(res, '');
      return {
        data: response?.data,
        status: true,
        message: '',
      };
    } catch (error: any) {
      return {
        data: null,
        status: false,
        message: error.response?.data?.error || COMMON_ERROR,
      };
    }
  }

  static async Delete<RQ, RES>(url: string, data?: RQ) {
    try {
      const headers = await this.getHeaders();
      const res = await axiosInstance.delete<RES>(url, {
        headers,
        data,
      });
      const response = this.SerializeResponse<RES>(res, '');
      return {
        data: response?.data,
        status: true,
        message: '',
      };
    } catch (error: any) {
      return {
        data: null,
        status: false,
        message: error.response?.data?.message || COMMON_ERROR,
      };
    }
  }

  static async getHeaders(): Promise<Record<string, string>> {
    const authToken = await this.getAuthToken();
    return {
      'Content-Type': 'application/json',
      'Authorization': authToken,
    };
  }

  static async getAuthToken(): Promise<string> {
    const token = await getAuthTokenFromLS();
    return `Bearer ${token}`;
  }
}
