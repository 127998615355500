import toast from 'react-hot-toast';

import { LOCAL_CONSTANT } from '@/constants';
import { JSONParse } from '@/utils/jsonparse';

import { axiosInstance } from './ApiInstance';

export const setupAxiosInterceptor = () => {
  axiosInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem(LOCAL_CONSTANT.USER_TOKEN);
    const tokenFromSS = sessionStorage.getItem(LOCAL_CONSTANT.USER_TOKEN);
    const userToken = JSONParse<{ accessToken: string }>(
      token || tokenFromSS || '',
    );
    if (config?.headers) {
      config.headers.Authorization = userToken ? `Bearer ${userToken}` : '';
    }
    return config;
  });

  axiosInstance.interceptors.response.use(
    (response) => {
      return response.data;
    },
    (error) => {
      switch (error.response?.status) {
        case 401: {
          localStorage.clear();
          window.location.reload();
          toast.error('Your session has expired. Please sign-in again.');
          return Promise.reject(error);
        }
        case 403: {
          setTimeout(() => {
            toast.error('Access denied. Your token is no longer valid.');
          }, 100);
          return Promise.reject(error);
        }
        default: {
          return Promise.reject(error);
        }
      }
    },
  );
};
