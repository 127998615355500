import React, { useRef } from 'react';
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { FaPlus } from 'react-icons/fa6';
import { HiOutlineTrash } from 'react-icons/hi';

import { IWorkExperiences } from '@/@types/profile';
import Container from '@/components/Container';
import FormField from '@/components/FormField';
import { Typography } from '@/components/Typography';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';
import { Textarea } from '@/components/ui/textarea';
import { WORK_EXPERIENCE_FIELDS } from '@/constants/profile';
import { strings } from '@/locales';
import { useFormContext } from '@/provider/FormProvider/index';
import { workExperienceSchema } from '@/schema/profile';
import { zodResolver } from '@hookform/resolvers/zod';

const WorkExperience = () => {
  const { profile: staticText, common } = strings;

  const { stepFour, setData, handleNext, handleBack, handleSkip } =
    useFormContext();

  const newSectionRef = useRef<HTMLDivElement | null>(null);

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<IWorkExperiences>({
    mode: 'all',
    resolver: zodResolver(workExperienceSchema),
    defaultValues: stepFour || {},
  });

  const { fields, append, remove } = useFieldArray<
    IWorkExperiences,
    'workExperience',
    'id'
  >({
    control,
    name: 'workExperience',
  });

  const addNewSection = () => {
    append({
      nameOfCompany: '',
      position: '',
      salary: 0,
      startDate: '',
      endDate: '',
      reasonForLeaving: '',
    });
    setTimeout(() => {
      if (newSectionRef.current) {
        newSectionRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }, 100);
  };

  const deleteSection = (index: number) => {
    remove(index);
  };

  const onSubmit: SubmitHandler<IWorkExperiences> = (data) => {
    // TODO: API call
    setData({ step: 4, data });
    handleNext();
  };

  return (
    <form>
      <Container className='rounded-t-none border-t-0 p-4 w-[95%] mx-auto'>
        <div className='mt-10 md:mt-20'>
          <div className='flex justify-between'>
            <Typography variant='heading' className='md:text-2xl'>
              {staticText.workExperience}
            </Typography>
            <div onClick={addNewSection}>
              <Typography className='text-primary font-semibold cursor-pointer flex items-center gap-1'>
                <FaPlus />
                {staticText.buttons.addNew}
              </Typography>
            </div>
          </div>
          {fields.map((field, sectionIndex) => (
            <div
              key={field.id}
              id={`section-${sectionIndex}`}
              ref={newSectionRef}
            >
              {sectionIndex !== 0 ? (
                <>
                  <Separator className='mt-5 mb-2' />
                  <div className='flex justify-end'>
                    <div onClick={() => deleteSection(sectionIndex)}>
                      <Typography className='text-tomatoRed mb-1 font-semibold cursor-pointer flex items-center gap-1'>
                        <HiOutlineTrash />
                        {staticText.buttons.delete}
                      </Typography>
                    </div>
                  </div>
                </>
              ) : null}
              <div className='md:grid md:grid-cols-3 gap-x-4 mt-4'>
                {WORK_EXPERIENCE_FIELDS.map((fieldConfig, index) => (
                  <div key={`${fieldConfig.name}_${index}`}>
                    <Controller
                      control={control}
                      name={
                        `workExperience.${sectionIndex}.${fieldConfig.name}` as keyof IWorkExperiences
                      }
                      render={({ field: { onChange, value, name } }) => (
                        <FormField
                          title={fieldConfig.title}
                          placeholder={common.typeHere}
                          name={name}
                          value={value as unknown as string | number}
                          onChange={onChange}
                          errors={errors}
                          labelClassName='font-bold md:text-base mb-3'
                        />
                      )}
                    />
                  </div>
                ))}
                <div className='col-span-2'>
                  <Typography className='flex capitalize font-bold md:text-base mb-3'>
                    {staticText.reasonForLeaving}
                  </Typography>
                  <Controller
                    control={control}
                    name={
                      `workExperience.${sectionIndex}.reasonForLeaving` as const
                    }
                    render={({ field: { onChange, value } }) => (
                      <Textarea
                        rows={4}
                        value={value as string}
                        onChange={onChange}
                        errors={errors}
                        placeholder={common.typeHere}
                        className='border-greyWhite rounded-xl resize-none w-full placeholder:text-quickSilver placeholder:text-sm text-sm md:text-sm font-medium min-h-10 text-primaryBlack max-h-40 mt-1 p-2'
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </Container>
      <div className='w-[95%] mx-auto py-3 flex justify-between'>
        <div onClick={() => handleSkip(4)}>
          <Typography className='text-primary underline font-semibold cursor-pointer'>
            {staticText.buttons.skip}
          </Typography>
        </div>
        <div className='flex gap-3'>
          <Button
            variant='outline'
            className='md:p-3 lg:p-5 focus-visible:ring-0 sm:w-44 md:w-32 font-medium sm:text-base !ring-offset-0'
            onClick={handleBack}
          >
            {staticText.buttons.back}
          </Button>
          <Button className='sm:w-44 md:w-32' onClick={handleSubmit(onSubmit)}>
            {staticText.buttons.next}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default WorkExperience;
