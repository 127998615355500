import { ForgotPasswordEmailFieldsTypes } from '@/@types/auth';
import { API } from '@/api/ApiInstance';
import { COMMON_ERROR } from '@/constants';
import { REST_SUB_URL } from '@/constants/urls';

import {
  ILoginRequest,
  ILoginResponse,
  IOtpFieldsTypes,
  IResetPasswordFields,
  OtpFieldsResponseType,
} from '../@types/auth';

export const login = async (
  payload: ILoginRequest,
): Promise<ILoginResponse | null | string> => {
  try {
    const response = await API.Post<ILoginRequest, ILoginResponse>(
      REST_SUB_URL.LOGIN,
      payload,
    );
    if (response.data) {
      return response?.data;
    }
    throw new Error(response.message);
  } catch (error) {
    throw new Error(error instanceof Error ? error.message : COMMON_ERROR);
  }
};

export const forgotPassword = async (
  payload: ForgotPasswordEmailFieldsTypes,
): Promise<boolean | string> => {
  try {
    const response = await API.Post<ForgotPasswordEmailFieldsTypes, boolean>(
      REST_SUB_URL.FORGOT_PASSWORD,
      payload,
    );
    if (response.status) {
      return true;
    }
    throw new Error(response.message);
  } catch (error) {
    throw new Error(error instanceof Error ? error.message : COMMON_ERROR);
  }
};
export const verifyOtp = async (
  payload: IOtpFieldsTypes,
): Promise<OtpFieldsResponseType | null | string> => {
  try {
    const response = await API.Post<
      IOtpFieldsTypes,
      OtpFieldsResponseType | null
    >(REST_SUB_URL.VERIFY_RESET_PASSWORD, payload);
    if (response.status) {
      return response.data;
    }
    throw new Error(response.message);
  } catch (error) {
    throw new Error(error instanceof Error ? error.message : COMMON_ERROR);
  }
};
export const resetPassword = async (
  payload: IResetPasswordFields,
): Promise<boolean | string> => {
  try {
    const response = await API.Post<IResetPasswordFields, boolean>(
      REST_SUB_URL.RESET_PASSWORD,
      payload,
    );
    if (response.status) {
      return true;
    }
    throw new Error(response.message);
  } catch (error) {
    throw new Error(error instanceof Error ? error.message : COMMON_ERROR);
  }
};
