export const STEPPER_STEPS = [
  'Personal Info',
  'Academic History',
  'Training Seminars',
  'Work Experience',
  'Special Skills',
  'Others',
];

const POSITION_DROPDOWN_OPTIONS = [
  {
    label: 'Software Engineer',
    value: 'software_engineer',
  },
  {
    label: 'Graphic Designer',
    value: 'graphic_designer',
  },
];

const GENDER_DROPDOWN_OPTIONS = [
  {
    label: 'Male',
    value: 'male',
  },
  {
    label: 'Female',
    value: 'female',
  },
];

export const PERSONAL_INFO_FIELDS = [
  {
    name: 'positionDesired',
    title: 'Position Desired',
    isDropdown: true,
    dropdownOptions: POSITION_DROPDOWN_OPTIONS,
  },
  {
    name: 'expectedSalary',
    title: 'Expected Salary',
  },
  {
    name: 'name',
    title: 'Name',
  },
  {
    name: 'nickname',
    title: 'Nick Name',
  },
  {
    name: 'email',
    title: 'Email Address',
  },
  {
    name: 'contactNo',
    title: 'Contact No.',
  },
  {
    name: 'presentAddress',
    title: 'Present Address',
    colSpan: 2,
  },
  {
    name: 'civilStatus',
    title: 'Civil Status',
  },
  {
    name: 'permanentAddress',
    title: 'Permanent Address',
    colSpan: 2,
  },
  {
    name: 'sex',
    title: 'Sex',
    isDropdown: true,
    dropdownOptions: GENDER_DROPDOWN_OPTIONS,
  },
  {
    name: 'birthPlace',
    title: 'Birth Place',
  },
  {
    name: 'birthDate',
    title: 'Birth Date',
  },
  {
    name: 'age',
    title: 'Age',
  },
  {
    name: 'nationality',
    title: 'Nationality',
  },
  {
    name: 'height',
    title: 'Height',
  },
  {
    name: 'weight',
    title: 'Weight',
  },
  {
    name: 'religion',
    title: 'Religion',
  },
  {
    name: 'sssNo',
    title: 'SSS No.',
  },
  {
    name: 'tin',
    title: 'TIN',
  },
  {
    name: 'philHealthNo',
    title: 'Philhealth No.',
  },
  {
    name: 'hdmfNo',
    title: 'PAG-IBIG (HDMF) No.',
    isTwo: true,
  },
  {
    name: 'nameOfSpouse',
    title: 'Name of Spouse',
  },
  {
    name: 'occupationOfSpouse',
    title: 'Occupation',
    isTwo: true,
  },
  {
    name: 'nameOfMother',
    title: 'Name of Mother',
  },
  {
    name: 'occupationOfMother',
    title: 'Occupation',
    isTwo: true,
  },
  {
    name: 'nameOfFather',
    title: 'Name of Father',
  },
  {
    name: 'occupationOfFather',
    title: 'Occupation',
    isTwo: true,
  },
  {
    name: 'distinguishMarksFeatures',
    title: 'Any Distinguishing Marks/Features',
    colSpan: 3,
  },
];

export const ACADEMIC_HISTORY_FIELDS = [
  {
    name: 'college',
    title: 'College',
  },
  {
    name: 'collegeYearCovered',
    title: 'Year Covered',
  },
  {
    name: 'course',
    title: 'Course',
  },
  {
    name: 'vocational',
    title: 'Vocational',
  },
  {
    name: 'vocationalYearCovered',
    title: 'Year Covered',
  },
  {
    name: 'vocationalCourse',
    title: 'Course',
  },
  {
    name: 'highSchool',
    title: 'High School',
  },
  {
    name: 'highSchoolYearCovered',
    title: 'Year Covered',
  },
  {
    name: 'highSchoolCourse',
    title: 'Course',
  },
];

export const TRAINING_SEMINAR_FIELDS = [
  {
    name: 'courseTitle',
    title: 'Course Title',
  },
  {
    name: 'inclusiveDates',
    title: 'Inclusive Dates',
  },
  {
    name: 'venue',
    title: 'Venue',
  },
];

export const WORK_EXPERIENCE_FIELDS = [
  {
    name: 'nameOfCompany',
    title: 'Name of Company',
  },
  {
    name: 'position',
    title: 'Position',
  },
  {
    name: 'salary',
    title: 'Salary',
  },
  {
    name: 'startDate',
    title: 'Start Date',
  },
  {
    name: 'endDate',
    title: 'End Date',
  },
];

export const WORKING_RELATIVES_FIELDS = [
  {
    name: 'name',
    title: 'Name',
  },
  {
    name: 'relationship',
    title: 'Relationship',
  },
];

export const EMERGENCY_CONTACT_FIELDS = [
  {
    name: 'name',
    title: 'Name',
  },
  {
    name: 'relationship',
    title: 'Relationship',
  },
  {
    name: 'address',
    title: 'Address',
  },
  {
    name: 'telNo',
    title: 'Tel No.',
  },
];
