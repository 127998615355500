import { z } from 'zod';

export const personalInfoSchema = z.object({
  avatar: z.instanceof(File).nullable().optional(),
  resume: z.instanceof(File).nullable().optional(),
  positionDesired: z.string().optional(),
  expectedSalary: z.string().optional(),
  name: z.string().optional(),
  nickname: z.string().optional(),
  email: z.string().optional(),
  contactNo: z.string().optional(),
  presentAddress: z.string().optional(),
  civilStatus: z.string().optional(),
  permanentAddress: z.string().optional(),
  sex: z.string().optional(),
  birthPlace: z.string().optional(),
  birthDate: z.string().optional(),
  age: z.union([z.number(), z.string()]).optional(),
  nationality: z.string().optional(),
  height: z.string().optional(),
  weight: z.string().optional(),
  religion: z.string().optional(),
  sssNo: z.string().optional(),
  tin: z.string().optional(),
  philHealthNo: z.string().optional(),
  hdmfNo: z.string().optional(),
  nameOfSpouse: z.string().optional(),
  occupationOfSpouse: z.string().optional(),
  nameOfMother: z.string().optional(),
  occupationOfMother: z.string().optional(),
  nameOfFather: z.string().optional(),
  occupationOfFather: z.string().optional(),
  distinguishMarksFeatures: z.string().optional(),
});

export const academicHistorySchema = z.object({
  college: z.string().optional(),
  collegeYearCovered: z.string().optional(),
  collegeCourse: z.string().optional(),
  vocational: z.string().optional(),
  vocationalYearCovered: z.string().optional(),
  vocationalCourse: z.string().optional(),
  highSchool: z.string().optional(),
  highSchoolYearCovered: z.string().optional(),
  highSchoolCourse: z.string().optional(),
});

export const trainingSeminarSchema = z.object({
  trainingSeminar: z.array(
    z.object({
      courseTitle: z.string().optional(),
      inclusiveDates: z.string().optional(),
      venue: z.string().optional(),
    }),
  ),
});

export const workExperienceSchema = z.object({
  workExperience: z.array(
    z.object({
      nameOfCompany: z.string().optional(),
      position: z.string().optional(),
      salary: z.number().optional(),
      startDate: z.string().optional(),
      endDate: z.string().optional(),
      reasonForLeaving: z.string().optional(),
    }),
  ),
});

export const specialSkillsSchema = z.object({
  specialSkills: z.string().optional(),
});

const relationSchema = z.object({
  name: z.string().optional(),
  relationship: z.string().optional(),
});

const emergencySchema = z.object({
  name: z.string().optional(),
  relationship: z.string().optional(),
  address: z.string().optional(),
  telNo: z.string().optional(),
});

export const finalStepSchema = z.object({
  relation: z.array(relationSchema).optional(),
  chargedCourt: z.string().optional(),
  emergency: z.array(emergencySchema).optional(),
});
