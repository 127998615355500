export const ROUTES = {
  LOGIN: '/login',
  SIGNUP: '/sign-up',
  FORGOT_PASSWORD: '/forgot-password',
  FORGOT_PASSWORD_OTP: '/forgot-password-otp',
  SET_NEW_PASSWORD: '/set-new-password',
  DASHBOARD: '/dashboard',
  CHAT: '/chat',
  CANDIDATE: '/candidate',
  PROFILE: '/profile',
  MY_DOCUMENTS: '/my-documents',
  NOTIFICATIONS: '/notifications',
};

export const PUBLIC_ROUTES = {
  LOGIN: '/login',
  SIGNUP: '/sign-up',
  FORGOT_PASSWORD: '/forgot-password',
  FORGOT_PASSWORD_OTP: '/forgot-password-otp',
  SET_NEW_PASSWORD: '/set-new-password',
};
