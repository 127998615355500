import { cn } from '@/lib/utils';
import { useDrawerContext } from '@/provider/DrawerProvider';

interface IDrawerContainer {
  children: React.ReactNode;
}

const DrawerContainer = ({ children }: IDrawerContainer) => {
  const { drawerExpand, isSmallScreen, drawerClosing } = useDrawerContext();

  return (
    <div className='relative z-50'>
      {/* Drawer */}
      <div
        className={cn(
          'md:relative fixed overflow-y-scroll no-scrollbar border-r border-r-greyWhite bg-white transition-all duration-300 ease-in-out w-16',
          {
            'w-full md:w-48': drawerExpand,
            'slide-in-left': isSmallScreen && drawerExpand,
            'slide-out-left': isSmallScreen && drawerClosing,
          },
        )}
      >
        <div className='flex flex-col h-screen mx-auto max-w-screen-xl'>
          {children}
        </div>
      </div>
    </div>
  );
};

export default DrawerContainer;
