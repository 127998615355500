import { Typography } from '@/components/Typography';
import { cn } from '@/lib/utils';
import { useDrawerContext } from '@/provider/DrawerProvider';

export interface IOptionText {
  name: string;
  selected?: boolean;
  className?: string;
}

const OptionText = ({ name, selected, className }: IOptionText) => {
  const { drawerExpand } = useDrawerContext();
  if (!drawerExpand) {
    return null;
  }

  return (
    <Typography
      className={cn(
        'font-semibold capitalize',
        {
          'font-extrabold text-white': selected,
        },
        className,
      )}
    >
      {name}
    </Typography>
  );
};

export default OptionText;
