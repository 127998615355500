import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import toast from 'react-hot-toast';
import { FiUploadCloud } from 'react-icons/fi';
import { HiOutlineTrash } from 'react-icons/hi';
import { IoCloseOutline } from 'react-icons/io5';

import { cn } from '@/lib/utils';
import { strings } from '@/locales';

import { Button } from './ui/button';
import { Input } from './ui/input';
import Container from './Container';
import { Typography } from './Typography';

interface Props {
  buttonText?: string;
  iconClassName?: string;
  setFile: React.Dispatch<React.SetStateAction<File | null>>;
  fileSize?: number;
  acceptedFileFormat?: string[] | string;
  dropzoneClassName?: string;
  uploadingDivClassName?: string;
  fileName?: string;
  isResume?: boolean;
}

const mimeTypes: Record<string, string> = {
  '.pdf': 'application/pdf',
  '.docx':
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  '.doc': 'application/msword',
  '.jpeg': 'image/jpeg',
  '.png': 'image/png',
  '.csv': 'text/csv',
};

const getMimeTypes = (extensions: string[] | string): string[] => {
  if (Array.isArray(extensions)) {
    return extensions.map((ext) => mimeTypes[ext] || '');
  }
  return [mimeTypes[extensions] || ''];
};

const FileDropContainer: React.FC<Props> = ({
  buttonText,
  iconClassName,
  setFile,
  fileSize = 5,
  acceptedFileFormat = '.docx',
  dropzoneClassName,
  uploadingDivClassName,
  fileName,
  isResume,
}) => {
  const { common } = strings;

  const [percent, setPercent] = useState(0);
  const [uploadedFileSize, setUploadedFileSize] = useState(0);
  const remainingSeconds = ((100 - percent) / 10) * 0.5;

  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const FILE_SIZE_LIMIT = fileSize * 1024 * 1024;

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles[0]) {
      const [file] = acceptedFiles;
      setUploadedFileSize(Number((file.size / 1024 / 1024).toFixed(3)));
      if (file.size > FILE_SIZE_LIMIT) {
        toast.error(common.fileSizeError);
      } else {
        setPercent(0);
        if (percent !== 100) {
          if (intervalRef.current) {
            clearInterval(intervalRef.current);
          }

          intervalRef.current = setInterval(() => {
            setPercent((prev) => {
              if (prev >= 100) {
                if (intervalRef.current) {
                  clearInterval(intervalRef.current);
                }
                setFile(file);
                return 100;
              }
              return prev + 10;
            });
          }, 800);
        }
      }
    }
  }, []);

  const acceptedMimeTypes = getMimeTypes(
    Array.isArray(acceptedFileFormat)
      ? acceptedFileFormat
      : [acceptedFileFormat],
  );

  const acceptConfig = acceptedMimeTypes.reduce(
    (acc, mimeType) => {
      if (mimeType) {
        acc[mimeType] = Object.keys(mimeTypes).filter(
          (ext) => mimeTypes[ext] === mimeType,
        );
      }
      return acc;
    },
    {} as Record<string, string[]>,
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    noClick: true,
    accept: acceptConfig,
  });

  const handleClearFileUpload = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
    setPercent(0);
    setFile(null);
  };

  const statusMessage = useMemo(() => {
    if (remainingSeconds) {
      return `${remainingSeconds} ${common.secondsRemaining}`;
    } else if (!isResume) {
      return common.completed;
    }
    return null;
  }, [remainingSeconds, isResume]);

  if (!percent)
    return (
      <div
        {...getRootProps()}
        className={cn(
          'mt-1 h-44 flex flex-col justify-center items-center bg-simplyViolet rounded-xl cursor-pointer drag-area focus:outline-none',
          { 'flex-row gap-2 h-24': buttonText },
          dropzoneClassName,
        )}
        onClick={open}
      >
        <Input {...getInputProps()} />
        <FiUploadCloud
          className={cn('text-3xl text-mouseGrey', iconClassName)}
        />
        {!buttonText ? (
          <Typography className='font-bold text-mouseGrey'>
            {common['drag&drop']}
          </Typography>
        ) : null}
        <Typography className='md:text-sm text-medium text-mouseGrey flex items-center w-fit'>
          {!buttonText ? common.or : ''}
          <Button variant='link' className='p-0 m-1 w-fit'>
            {buttonText || common.browseFile}
          </Button>
          {!buttonText ? common.fromDevice : ''}
        </Typography>
      </div>
    );

  return (
    <Container height='h-fit' className={cn('mt-1', uploadingDivClassName)}>
      <div className='h-6'>
        <Typography className='md:text-sm font-semibold truncate max-w-full'>
          {percent === 100 ? (
            <>
              {fileName && fileName.length > 30
                ? `${fileName.slice(0, 30)}...`
                : fileName}
            </>
          ) : (
            common.uploading
          )}
        </Typography>
      </div>
      <div className='flex items-center justify-between'>
        <div className='flex items-center justify-between gap-2'>
          {isResume && percent === 100 ? (
            <Typography className='md:text-sm font-semibold text-quickSilver'>
              {uploadedFileSize} MB
            </Typography>
          ) : (
            <>
              <Typography className='md:text-sm font-semibold text-quickSilver'>
                {percent}%
              </Typography>
              <div className='size-1 rounded-full bg-quickSilver' />
            </>
          )}
          <Typography className='md:text-sm font-semibold text-quickSilver'>
            {statusMessage}
          </Typography>
        </div>
        <div
          className='p-1 rounded-full bg-tomatoRed/15'
          onClick={handleClearFileUpload}
        >
          {percent === 100 ? (
            <HiOutlineTrash className='text-tomatoRed text-lg' />
          ) : (
            <IoCloseOutline className='text-tomatoRed text-lg' />
          )}
        </div>
      </div>
      {!isResume || (isResume && percent !== 100) ? (
        <div className='mt-4 h-2 overflow-hidden rounded-full bg-snow'>
          <div
            className='duration-3000 h-5 transition-all ease-in-out bg-primary'
            style={{ width: `${percent}%` }}
          ></div>
        </div>
      ) : null}
    </Container>
  );
};

export default FileDropContainer;
