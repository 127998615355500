import React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { ISpecialSkills } from '@/@types/profile';
import Container from '@/components/Container';
import { Typography } from '@/components/Typography';
import { Button } from '@/components/ui/button';
import { Textarea } from '@/components/ui/textarea';
import { strings } from '@/locales';
import { useFormContext } from '@/provider/FormProvider/index';
import { specialSkillsSchema } from '@/schema/profile';
import { zodResolver } from '@hookform/resolvers/zod';

const SpecialSkills = () => {
  const { profile: staticText, common } = strings;
  const { stepFive, setData, handleNext, handleBack, handleSkip } =
    useFormContext();

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<ISpecialSkills>({
    mode: 'all',
    resolver: zodResolver(specialSkillsSchema),
    defaultValues: stepFive || {},
  });

  const onSubmit: SubmitHandler<ISpecialSkills> = (data) => {
    // TODO: API call
    setData({ step: 5, data });
    handleNext();
  };

  return (
    <form>
      <Container className='rounded-t-none border-t-0 p-4 w-[95%] mx-auto'>
        <div className='mt-10 md:mt-20'>
          <Typography variant='heading' className='md:text-2xl'>
            {staticText.specialSkills}
          </Typography>
          <div className='my-8'>
            <Typography className='flex capitalize font-bold md:text-base mb-3'>
              {staticText.skillsAndTalents}
            </Typography>
            <Controller
              control={control}
              name='specialSkills'
              render={({ field: { onChange, value } }) => (
                <Textarea
                  rows={4}
                  value={value as string}
                  onChange={onChange}
                  errors={errors}
                  placeholder={common.typeHere}
                  className='border-greyWhite rounded-xl resize-none w-full placeholder:text-quickSilver placeholder:text-sm text-sm md:text-sm font-medium min-h-10 text-primaryBlack max-h-40 mt-1 p-2'
                />
              )}
            />
          </div>
        </div>
      </Container>
      <div className='w-[95%] mx-auto py-3 flex justify-between'>
        <div onClick={() => handleSkip(5)}>
          <Typography className='text-primary underline font-semibold cursor-pointer'>
            {staticText.buttons.skip}
          </Typography>
        </div>
        <div className='flex gap-3'>
          <Button
            variant='outline'
            className='md:p-3 lg:p-5 focus-visible:ring-0 sm:w-44 md:w-32 font-medium sm:text-base !ring-offset-0'
            onClick={handleBack}
          >
            {staticText.buttons.back}
          </Button>
          <Button className='sm:w-44 md:w-32' onClick={handleSubmit(onSubmit)}>
            {staticText.buttons.next}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default SpecialSkills;
