import React, { createContext, ReactNode, useContext, useState } from 'react';

import {
  IAcademicHistory,
  IFinalStep,
  IPersonalInfo,
  ISpecialSkills,
  ITrainingSeminar,
  IWorkExperiences,
} from '@/@types/profile';

import { DEFAULT_STATE, FormState, setDataType } from './types';

const FormContext = createContext<FormState>(DEFAULT_STATE);

export const FormProvider = ({ children }: { children: ReactNode }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [skippedSteps, setSkippedSteps] = useState<number[]>([]);

  const handleNext = () => {
    setCurrentStep((prev) => (prev < 6 ? prev + 1 : prev));
  };

  const handleBack = () => {
    setCurrentStep((prev) => (prev > 1 ? prev - 1 : prev));
  };

  const handleSkip = (stepNumber: number) => {
    setSkippedSteps((prevSkipped) => [...prevSkipped, stepNumber]);
    handleNext();
  };

  const [stepOne, setStepOne] = useState<IPersonalInfo | null>(
    DEFAULT_STATE.stepOne,
  );
  const [stepTwo, setStepTwo] = useState<IAcademicHistory | null>(
    DEFAULT_STATE.stepTwo,
  );
  const [stepThree, setStepThree] = useState<ITrainingSeminar | null>(
    DEFAULT_STATE.stepThree,
  );
  const [stepFour, setStepFour] = useState<IWorkExperiences | null>(
    DEFAULT_STATE.stepFour,
  );
  const [stepFive, setStepFive] = useState<ISpecialSkills | null>(
    DEFAULT_STATE.stepFive,
  );
  const [stepSix, setStepSix] = useState<IFinalStep | null>(
    DEFAULT_STATE.stepSix,
  );

  const setData = ({ step, data }: setDataType) => {
    switch (step) {
      case 1:
        setStepOne(data);
        break;
      case 2:
        setStepTwo(data);
        break;
      case 3:
        setStepThree(data);
        break;
      case 4:
        setStepFour(data);
        break;
      case 5:
        setStepFive(data);
        break;
      case 6:
        setStepSix(data);
        break;
    }
  };

  const values = {
    currentStep,
    handleNext,
    handleBack,
    handleSkip,
    skippedSteps,
    stepOne,
    stepTwo,
    stepThree,
    stepFour,
    stepFive,
    stepSix,
    setData,
  };

  return <FormContext.Provider value={values}>{children}</FormContext.Provider>;
};

export const useFormContext = (): FormState => useContext(FormContext);
