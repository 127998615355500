/* eslint-disable max-lines */
import React from 'react';
import { CookiesProvider } from 'react-cookie';
import { Toaster } from 'react-hot-toast';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';

import Header from '@/components/Header';
import Sidebar from '@/components/Sidebar';
import { cn } from '@/lib/utils';
import { AuthProvider, useAuth } from '@/provider/AuthProvider';
import { DrawerProvider, useDrawerContext } from '@/provider/DrawerProvider';
import { FormProvider } from '@/provider/FormProvider/index';
import { PUBLIC_ROUTES, ROUTES } from '@/routes';

import ProtectedRoute from '../../routes/ProtectedRoute';
import PublicRoute from '../../routes/PublicRoute';
import ForgotPasswordEmail from '../Auth/ForgotPasswordEmail';
import ForgotPasswordOtp from '../Auth/ForgotPasswordOtp';
import SetNewPassword from '../Auth/SetNewPassword.tsx';
import SignIn from '../Auth/SignIn';
import SignUp from '../Auth/SignUp';
import Chat from '../Chat';
import Dashboard from '../Dashboard';
import MyDocument from '../MyDocuments';
import Notifications from '../Notifications';
import Profile from '../Profile';

import 'src/assets/styles/App.css';

const App = () => {
  const { drawerExpand, isSmallScreen } = useDrawerContext();
  const { pathname } = useLocation();
  const { token } = useAuth();
  const hideLayout = Object.values(PUBLIC_ROUTES).includes(pathname) || !token;

  const handleRouteNotFound = () => {
    let redirectTo;

    if (token) {
      redirectTo = ROUTES.DASHBOARD;
    } else {
      redirectTo = ROUTES.LOGIN;
    }

    return <Navigate to={redirectTo} />;
  };

  return (
    <div className='flex h-screen'>
      {isSmallScreen && !hideLayout ? (
        <div className={cn({ hidden: !drawerExpand })}>
          <Sidebar />
        </div>
      ) : null}
      <div className='w-full bg-simplyViolet overflow-scroll flex flex-col'>
        <Toaster />
        {hideLayout ? null : <Header />}
        <Routes>
          <Route
            path={ROUTES.DASHBOARD}
            element={<ProtectedRoute element={<Dashboard />} />}
          />
          <Route
            path={ROUTES.PROFILE}
            element={<ProtectedRoute element={<Profile />} />}
          />
          <Route
            path={ROUTES.MY_DOCUMENTS}
            element={<ProtectedRoute element={<MyDocument />} />}
          />
          <Route
            path={ROUTES.LOGIN}
            element={<PublicRoute element={<SignIn />} />}
          />
          <Route
            path={ROUTES.SIGNUP}
            element={<PublicRoute element={<SignUp />} />}
          />
          <Route
            path={ROUTES.FORGOT_PASSWORD}
            element={<PublicRoute element={<ForgotPasswordEmail />} />}
          />
          <Route
            path={ROUTES.FORGOT_PASSWORD_OTP}
            element={<PublicRoute element={<ForgotPasswordOtp />} />}
          />
          <Route
            path={ROUTES.SET_NEW_PASSWORD}
            element={<PublicRoute element={<SetNewPassword />} />}
          />
          <Route
            path={ROUTES.CHAT}
            element={<ProtectedRoute element={<Chat />} />}
          />
          <Route
            path={ROUTES.NOTIFICATIONS}
            element={<ProtectedRoute element={<Notifications />} />}
          />
          <Route path='*' element={handleRouteNotFound()} />
        </Routes>
      </div>
    </div>
  );
};

const AppWrapper = () => {
  return (
    <Router>
      <CookiesProvider>
        <AuthProvider>
          <FormProvider>
            <DrawerProvider>
              <App />
            </DrawerProvider>
          </FormProvider>
        </AuthProvider>
      </CookiesProvider>
    </Router>
  );
};

export default AppWrapper;
