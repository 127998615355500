import React, { ReactNode } from 'react';
import { IoIosInformationCircleOutline } from 'react-icons/io';

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { cn } from '@/lib/utils';

type TooltipComponentProps = {
  trigger?: ReactNode | string;
  text?: string;
  children?: ReactNode;
  className?: string;
  align?: 'end' | 'center' | 'start';
  directionalArrow?: boolean;
};

type TooltipContentProps = TooltipComponentProps & {
  text?: never;
};

type TooltipTextProps = TooltipComponentProps & {
  children?: never;
};

const TooltipComponent: React.FC<TooltipContentProps | TooltipTextProps> = ({
  trigger = <IoIosInformationCircleOutline />,
  text,
  children,
  align = 'end',
  className = 'max-w-64',
  directionalArrow = false,
}) => {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger>
          {typeof trigger === 'string' ? <span>{trigger}</span> : trigger}
        </TooltipTrigger>
        <TooltipContent
          className={cn(
            {
              'before:w-2 before:h-2 before:rotate-45 before:shadow-md before:bg-white before:absolute before:-bottom-1 before:left-0 before:right-0 before:mx-auto':
                directionalArrow,
            },
            className,
          )}
          align={align}
        >
          {text ? <p>{text}</p> : children}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export default TooltipComponent;
