import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { ISignUpFields } from '@/@types/auth';
import FormField from '@/components/FormField';
import { Typography } from '@/components/Typography';
import { Button } from '@/components/ui/button';
import { INPUT_ERRORS } from '@/constants';
import { strings } from '@/locales';
import { ROUTES } from '@/routes';
import { EmailRegex } from '@/utils/common';

import GoogleIcon from '../../../assets/svgs/google.svg';
import AuthHeadingsAndDesc from '../components/HeadingAndDesc';
import LineForOrOtion from '../components/LineForOrOtion';

const SignUp = () => {
  const staticText = strings.signUp;
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const initialValues = {
    fullName: '',
    email: '',
    password: '',
    reEnterPassword: '',
  };

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<ISignUpFields>({
    mode: 'all',
    defaultValues: initialValues,
  });
  const watchPassword = watch('password');

  const handleSignUp: SubmitHandler<ISignUpFields> = (formData) => {
    // TODO: conole will be removed after api integration
    console.log(formData);
    setIsLoading(false);
    // TODO: console will be removed after api integration
    navigate(ROUTES.LOGIN);
  };
  return (
    <>
      <AuthHeadingsAndDesc title={staticText.title} desc={staticText.desc} />
      <div className='space-y-4'>
        <Controller
          control={control}
          name='fullName'
          rules={{
            required: INPUT_ERRORS.NAME.required,
          }}
          render={({ field: { onChange, value, name } }) => (
            <FormField
              title={staticText.name}
              placeholder={staticText.namePlaceholder}
              name={name}
              value={value}
              isRequired
              onChange={onChange}
              errors={errors}
            />
          )}
        />
        <Controller
          control={control}
          name='email'
          rules={{
            required: INPUT_ERRORS.EMAIL.required,
            pattern: {
              value: EmailRegex,
              message: INPUT_ERRORS.EMAIL.pattern,
            },
          }}
          render={({ field: { onChange, value, name } }) => (
            <FormField
              title={staticText.email}
              placeholder={staticText.emailPlaceholder}
              name={name}
              value={value}
              isRequired
              onChange={onChange}
              errors={errors}
            />
          )}
        />
        <Controller
          control={control}
          name='password'
          rules={{
            required: INPUT_ERRORS.PASSWORD.required,
          }}
          render={({ field: { onChange, value, name } }) => (
            <FormField
              title={staticText.password}
              placeholder={staticText.passwodPlaceholder}
              name={name}
              value={value}
              isRequired
              onChange={onChange}
              type='password'
              errors={errors}
            />
          )}
        />
        <Controller
          control={control}
          name='reEnterPassword'
          rules={{
            required: INPUT_ERRORS.PASSWORD.required,
            validate: (value) =>
              value === watchPassword || INPUT_ERRORS.CONFIRM_PASSWORD.match,
          }}
          render={({ field: { onChange, value, name } }) => (
            <FormField
              title={staticText.reEnterPassword}
              placeholder={staticText.reEnterPasswordPlaceholder}
              name={name}
              value={value}
              type='password'
              isRequired
              onChange={onChange}
              errors={errors}
            />
          )}
        />
        <Button
          className='!mt-6 text-base'
          onClick={handleSubmit(handleSignUp)}
          loading={isLoading}
        >
          {staticText.btnText}
        </Button>
        <LineForOrOtion />
        <Button className='border bg-white border-greyWhite text-black text-base'>
          {staticText.btnTwoText}
          <img src={GoogleIcon} alt='google' className='ml-1' />
        </Button>
        <Typography className='text-center w-full'>
          {staticText.alreadyHaveAnAccount}
          &nbsp;
          <a className='cursor-pointer text-primary' href={ROUTES.LOGIN}>
            {staticText.signIn}
          </a>
        </Typography>
      </div>
    </>
  );
};
// eslint-disable-next-line max-lines
export default SignUp;
