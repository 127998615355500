import React from 'react';
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form';

import { ITrainingSeminar } from '@/@types/profile';
import Container from '@/components/Container';
import FormField from '@/components/FormField';
import { Typography } from '@/components/Typography';
import { Button } from '@/components/ui/button';
import { TRAINING_SEMINAR_FIELDS } from '@/constants/profile';
import { strings } from '@/locales';
import { useFormContext } from '@/provider/FormProvider/index';
import { trainingSeminarSchema } from '@/schema/profile';
import { zodResolver } from '@hookform/resolvers/zod';

const TrainingSeminar = () => {
  const { profile: staticText, common } = strings;
  const { stepThree, setData, handleNext, handleBack, handleSkip } =
    useFormContext();

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<ITrainingSeminar>({
    mode: 'all',
    resolver: zodResolver(trainingSeminarSchema),

    defaultValues: stepThree || {},
  });

  const { fields } = useFieldArray<ITrainingSeminar, 'trainingSeminar', 'id'>({
    control,
    name: 'trainingSeminar',
  });

  const onSubmit: SubmitHandler<ITrainingSeminar> = (data) => {
    // TODO: API call
    setData({ step: 3, data });
    handleNext();
  };

  return (
    <form>
      <Container className='rounded-t-none border-t-0 p-4 w-[95%] mx-auto'>
        <div className='mt-10 md:mt-20'>
          <Typography variant='heading' className='md:text-2xl'>
            {staticText.trainingSeminars}
          </Typography>
          {fields.map((field, sectionIndex) => (
            <div key={field.id} className='md:grid md:grid-cols-3 gap-x-4 mt-4'>
              {TRAINING_SEMINAR_FIELDS.map((fieldConfig, index) => (
                <div key={`${fieldConfig.name}_${index}`}>
                  <Controller
                    control={control}
                    name={
                      `trainingSeminar.${sectionIndex}.${fieldConfig.name}` as keyof ITrainingSeminar
                    }
                    render={({ field: { onChange, value, name } }) => (
                      <FormField
                        title={fieldConfig.title}
                        placeholder={common.typeHere}
                        name={name}
                        value={value as unknown as string | number}
                        onChange={onChange}
                        errors={errors}
                        labelClassName='font-bold md:text-base mb-3'
                      />
                    )}
                  />
                </div>
              ))}
            </div>
          ))}
        </div>
      </Container>
      <div className='w-[95%] mx-auto py-3 flex justify-between'>
        <div onClick={() => handleSkip(3)}>
          <Typography className='text-primary underline font-semibold cursor-pointer'>
            {staticText.buttons.skip}
          </Typography>
        </div>
        <div className='flex gap-3'>
          <Button
            variant='outline'
            className='md:p-3 lg:p-5 focus-visible:ring-0 sm:w-44 md:w-32 font-medium sm:text-base !ring-offset-0'
            onClick={handleBack}
          >
            {staticText.buttons.back}
          </Button>
          <Button className='sm:w-44 md:w-32' onClick={handleSubmit(onSubmit)}>
            {staticText.buttons.next}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default TrainingSeminar;
