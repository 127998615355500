import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FiTrash2 } from 'react-icons/fi';
import { MdOutlineArrowBackIosNew } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

import Container from '@/components/Container';
import FileDropContainer from '@/components/FileDropContainer';
import { Typography } from '@/components/Typography';
import { Button } from '@/components/ui/button';
import { strings } from '@/locales';
import { ROUTES } from '@/routes';

import DefaultIcon from '../../assets/svgs/defaultIcon.svg';
import PDFicon from '../../assets/svgs/pdfIcon.svg';
import WordIcon from '../../assets/svgs/wordIcon.svg';

interface FormData {
  medicalCertificate: File | null;
  medicalInsurance: File | null;
  policeClearance: File | null;
  diploma: File | null;
}

const MyDocument: React.FC = () => {
  const { control, watch, setValue, reset } = useForm<FormData>({
    defaultValues: {
      medicalCertificate: null,
      medicalInsurance: null,
      policeClearance: null,
      diploma: null,
    },
  });

  const navigate = useNavigate();
  const staticText = strings.myDocumentsScreen;

  const watchAllFields = watch();

  const handleNavigate = () => {
    navigate(ROUTES.DASHBOARD);
  };

  const handleDelete = (fieldName: keyof FormData) => {
    setValue(fieldName, null);
  };

  const handleSave = () => {
    // Implement save BackEnd logic here
    reset(watchAllFields); // Reset form state to consider current data as "initial"
  };

  const getFileSize = (file: File | null) =>
    file ? `${(file.size / (1024 * 1024)).toFixed(1)} MB` : '';

  const getFileIcon = (fileName: string) => {
    const extension = fileName.split('.').pop()?.toLowerCase();
    switch (extension) {
      case 'pdf':
        return PDFicon;
      case 'doc':
      case 'docx':
        return WordIcon;
      default:
        return DefaultIcon;
    }
  };

  const uploadedDocuments = Object.entries(watchAllFields).filter(
    ([, file]) => file !== null,
  );

  const hasChanges = Object.values(watchAllFields).some(
    (file) => file !== null,
  );

  return (
    <>
      <div className='flex items-center mb-6'>
        <Button
          variant='ghost'
          size='icon'
          onClick={handleNavigate}
          className='text-mouseGrey bg-white rounded-md mr-4'
        >
          <MdOutlineArrowBackIosNew />
        </Button>

        <Typography variant='heading' className='md:text-2xl'>
          {staticText.myDocuments}
        </Typography>
      </div>
      <Container>
        <Typography variant='subheading' className='mb-6 font-bold'>
          {staticText.uploadDocuments}
        </Typography>

        <div className='mt-4 mb-8 grid grid-cols-1 custombp:grid-cols-2 gap-6'>
          {[
            {
              title: staticText.medicalCertificate,
              name: 'medicalCertificate' as keyof FormData,
            },
            {
              title: staticText.medicalInsurance,
              name: 'medicalInsurance' as keyof FormData,
            },
            {
              title: staticText.policeClearance,
              name: 'policeClearance' as keyof FormData,
            },
            { title: staticText.diploma, name: 'diploma' as keyof FormData },
          ].map((item) => (
            <div key={item.name} className='flex flex-col h-full'>
              <div className='mb-3'>
                <Typography
                  variant='subheading'
                  className='font-extrabold md:text-base'
                >
                  {item.title}
                </Typography>
              </div>
              <div className='h-32'>
                <Controller
                  name={item.name}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FileDropContainer
                      setFile={(file) => onChange(file)}
                      acceptedFileFormat={['.pdf', '.docx']}
                      fileName={value?.name}
                      buttonText={staticText.upload}
                      iconClassName='bg-primary rounded-full text-white text-4xl p-2'
                      dropzoneClassName='h-full'
                      uploadingDivClassName='h-full'
                    />
                  )}
                />
              </div>
            </div>
          ))}
        </div>

        <div className='block mb-6'>
          <Typography variant='subheading' className='font-bold'>
            {staticText.uploadedDocuments}
          </Typography>
        </div>

        {uploadedDocuments.length === 0 ? (
          <Typography className='mt-4 text-center text-mouseGrey'>
            {staticText.noData}
          </Typography>
        ) : (
          <div className='grid grid-cols-1 custombp:grid-cols-2 gap-6'>
            {uploadedDocuments.map(([fieldName, file]) => (
              <div className='block' key={fieldName}>
                <Typography
                  variant='subheading'
                  className='font-extrabold md:text-base mb-2'
                >
                  {staticText[fieldName as keyof typeof staticText]}
                </Typography>
                <div className='border-greyWhite border flex items-center p-3 sm:p-4 bg-simplyViolet rounded-xl'>
                  <div className='flex items-center gap-3 sm:gap-4 flex-grow'>
                    <div className='bg-white border-greyWhite border rounded size-4 sm:w-12 sm:h-12 flex justify-center items-center flex-shrink-0'>
                      <img
                        src={file ? getFileIcon(file.name) : PDFicon}
                        alt='Document-icon'
                        className='size-6 sm:size-8'
                      />
                    </div>
                    <div className='flex flex-col overflow-hidden flex-grow'>
                      <Typography className='text-xs sm:text-sm text-mouseGrey truncate'>
                        {file?.name}
                      </Typography>
                      <Typography className='text-xs sm:text-sm text-mouseGrey'>
                        {getFileSize(file)}
                      </Typography>
                    </div>
                    <Button
                      variant='ghost'
                      size='icon'
                      onClick={() => handleDelete(fieldName as keyof FormData)}
                      className='text-redColor bg-white flex-shrink-0 ml-2'
                    >
                      <FiTrash2 size={16} />
                    </Button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}

        <div className='mt-8 flex justify-end'>
          <Button
            onClick={handleSave}
            disabled={!hasChanges}
            className='bg-primary text-white w-32 h-11'
          >
            {staticText.save}
          </Button>
        </div>
      </Container>
    </>
  );
};

export default MyDocument;
