import { HiDownload } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';

import { cn } from '@/lib/utils';
import { useAuth } from '@/provider/AuthProvider';
import { useDrawerContext } from '@/provider/DrawerProvider';
import { ROUTES } from '@/routes';
import { snakeToTitleCase } from '@/utils/common';

import Logo from '../../assets/images/logo.svg';
import ProfileBadge from '../ProfilBadge';
import { Button } from '../ui/button';

const DrawerHeader = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { drawerExpand, setDrawerExpand, setDrawerClosing, isSmallScreen } =
    useDrawerContext();

  const { firstName, lastName, avatar, profileType } = user || {};

  const handleDrawerClose = () => {
    setDrawerClosing(true);
    setDrawerExpand((prev) => !prev);
    setDrawerClosing(false);
  };

  return (
    <div
      className={cn('cursor-pointer flex justify-center h-20 items-center', {
        'justify-between mx-3': drawerExpand,
      })}
    >
      <ProfileBadge
        name={`${firstName} ${lastName}`}
        profilePicture={avatar as string}
        attribute={snakeToTitleCase(profileType?.toLowerCase())}
        className='md:hidden'
      />
      <div
        className={cn('hidden md:block w-12', {
          'w-20': drawerExpand,
        })}
        onClick={() => navigate(ROUTES.DASHBOARD)}
      >
        <img src={Logo} alt='Logo' />
      </div>
      <Button
        variant='ghost'
        size='icon'
        icon={<HiDownload className='rotate-90 text-primaryBlack text-xl' />}
        className={cn('size-8 mt-4', {
          hidden: !drawerExpand,
        })}
        onClick={
          isSmallScreen
            ? handleDrawerClose
            : () => setDrawerExpand((prev) => !prev)
        }
      />
    </div>
  );
};

export default DrawerHeader;
