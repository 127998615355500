import React, { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { ForgotPasswordEmailFieldsTypes } from '@/@types/auth';
import FormField from '@/components/FormField';
import { Typography } from '@/components/Typography';
import { Button } from '@/components/ui/button';
import { COMMON_ERROR, INPUT_ERRORS } from '@/constants';
import { useGenericMutation } from '@/hooks/useMutationData';
import { strings } from '@/locales';
import { ROUTES } from '@/routes';
import { forgotPassword } from '@/services/auth';
import { EmailRegex } from '@/utils/common';

import AuthHeadingsAndDesc from '../components/HeadingAndDesc';

const ForgotPasswordEmail = () => {
  const staticText = strings.forgotPasswordEmailScreen;
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitted },
  } = useForm<ForgotPasswordEmailFieldsTypes>({
    mode: 'all',
    defaultValues: {
      email: '',
    },
  });

  const forgotPasswordMutation = useGenericMutation<
    ForgotPasswordEmailFieldsTypes,
    boolean | string
  >(forgotPassword, {
    onError: (error: unknown) => {
      toast.error(error instanceof Error ? error.message : COMMON_ERROR);
    },
    onSuccess: (response) => {
      if (response === true) {
        toast.success(staticText.otpSend);
        navigate(ROUTES.FORGOT_PASSWORD_OTP, { state: { email } });
      } else {
        toast.error(response as string);
      }
    },
  });

  const handleSubmitEmail: SubmitHandler<ForgotPasswordEmailFieldsTypes> = (
    data,
  ) => {
    forgotPasswordMutation.mutate(data);
    setEmail(data.email);
  };

  return (
    <>
      <AuthHeadingsAndDesc title={staticText.title} desc={staticText.desc} />
      <div className='space-y-4'>
        <Controller
          control={control}
          name='email'
          rules={{
            required: INPUT_ERRORS.EMAIL.required,
            pattern: {
              value: EmailRegex,
              message: INPUT_ERRORS.EMAIL.pattern,
            },
          }}
          render={({ field: { onChange, value, name } }) => (
            <FormField
              title={staticText.email}
              placeholder={staticText.emailPlaceholder}
              name={name}
              value={value}
              isRequired
              onChange={onChange}
              errors={isSubmitted ? errors : {}}
            />
          )}
        />
        <Button
          className='text-base'
          onClick={handleSubmit(handleSubmitEmail)}
          disabled={forgotPasswordMutation.status === 'pending'}
          loading={forgotPasswordMutation.status === 'pending'}
        >
          {staticText.btnText}
        </Button>
        <Typography className='text-center w-full flex items-center justify-center mt-3'>
          {staticText.backTo}
          &nbsp;
          <a className='cursor-pointer text-primary' href={ROUTES.LOGIN}>
            {staticText.signIn}
          </a>
        </Typography>
      </div>
    </>
  );
};

export default ForgotPasswordEmail;
