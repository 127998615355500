import React from 'react';

import Container from '@/components/Container';
import { useFormContext } from '@/provider/FormProvider';

import AcademicHistory from './Stepper/AcademicHistory';
import FinalStep from './Stepper/FinalStep';
import PersonalInfo from './Stepper/PersonalInfo';
import SpecialSkills from './Stepper/SpecialSkills';
import TrainingSeminar from './Stepper/TrainingSeminar';
import WorkExperience from './Stepper/WorkExperience';
import Stepper from './Stepper';

const Profile = () => {
  const { currentStep } = useFormContext();

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return <PersonalInfo />;
      case 2:
        return <AcademicHistory />;
      case 3:
        return <TrainingSeminar />;
      case 4:
        return <WorkExperience />;
      case 5:
        return <SpecialSkills />;
      case 6:
        return <FinalStep />;
      default:
        return null;
    }
  };

  return (
    <>
      <Container className='mt-8 rounded-b-none border-b-0 p-4 w-[95%] mx-auto'>
        <Stepper />
      </Container>
      {renderStepContent()}
    </>
  );
};

export default Profile;
