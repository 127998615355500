/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import OtpInput from 'react-otp-input';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  ForgotPasswordEmailField,
  IOtpFields,
  OtpFieldsResponseType,
} from '@/@types/auth';
import Loader from '@/components/Loader';
import { Typography } from '@/components/Typography';
import { Button } from '@/components/ui/button';
import { COMMON_ERROR } from '@/constants';
import { useGenericMutation } from '@/hooks/useMutationData';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import { ROUTES } from '@/routes';
import { forgotPassword, verifyOtp } from '@/services/auth';
import { formatEmailAddress } from '@/utils/common';
import { setTokenToSS } from '@/utils/sessionstorage';

import AuthHeadingsAndDesc from '../components/HeadingAndDesc';

const ForgotPasswordOtp = () => {
  const staticText = strings.forgotPasswordEmailScreen;

  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location as { state: ForgotPasswordEmailField };
  const { email } = state || {};

  const [otp, setOtp] = useState('');
  const [timer, setTimer] = useState(60);
  const [status, setStatus] = useState<'error' | 'success' | null>(null);

  const handleOtpChange = (newOtp: string) => {
    setOtp(newOtp);
  };

  const otpMutation = useGenericMutation<
    IOtpFields,
    OtpFieldsResponseType | null | string
  >(verifyOtp, {
    onError: (error: unknown) => {
      toast.error(error instanceof Error ? error.message : COMMON_ERROR);
      setStatus('error');
    },
    onSuccess: (response) => {
      if (response && typeof response === 'object') {
        setStatus('success');
        toast.success(staticText.otpVerified);
        setTokenToSS(response.accessToken);
        setTimeout(() => {
          navigate(ROUTES.SET_NEW_PASSWORD);
        }, 1000);
      } else {
        toast.error(response);
      }
    },
  });

  const handleOtpSubmit = () => {
    const payload = {
      email: email,
      otp: Number(otp),
    };
    otpMutation.mutate(payload);
  };

  const forgotPasswordOtpMutation = useGenericMutation<
    ForgotPasswordEmailField,
    boolean | string
  >(forgotPassword, {
    onError: (error: unknown) => {
      toast.error(error instanceof Error ? error.message : COMMON_ERROR);
    },
    onSuccess: (response) => {
      if (response) {
        toast.success(staticText.otpSend);
        setTimer(60);
      } else {
        toast.error(COMMON_ERROR);
      }
    },
  });

  const handleResend = () => {
    forgotPasswordOtpMutation.mutate({ email });
    setOtp('');
  };

  const formatTime = (seconds: number) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs < 10 ? '0' : ''}${secs} ${staticText[mins ? 'minute' : 'seconds']}`;
  };

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [timer]);

  useEffect(() => {
    if (status) setStatus(null);
  }, [otp.length]);

  const RenderSeparator = (index: number) => {
    return index === 2 ? <span className='text-2xl'>-</span> : null;
  };

  return (
    <>
      <AuthHeadingsAndDesc
        title={staticText.title}
        desc={staticText.descWithEmail.replace(
          '{{emailAddress}}',
          formatEmailAddress(email),
        )}
      />
      <div>
        <div className='flex justify-center'>
          <OtpInput
            value={otp}
            onChange={handleOtpChange}
            containerStyle='otp-input flex gap-2 md:gap-4 justify-between w-full'
            inputStyle={cn(
              'rounded-md flex-grow focus:outline-primary bg-white border-greyWhite border h-8 xl:h-12 text-center text-lg',
              {
                'outline outline-greenRevolution': status === 'success',
                'outline outline-tomatoRed': status === 'error',
              },
            )}
            numInputs={6}
            skipDefaultStyles
            renderSeparator={RenderSeparator}
            renderInput={(props) => (
              <input
                {...props}
                className={cn(
                  'flex-grow basis-[30px] max-w-8 xl:min-w-12 xl:max-w-[50px]',
                  props.className,
                )}
              />
            )}
          />
        </div>
        <div className='flex mt-2'>
          {forgotPasswordOtpMutation.status === 'pending' ? (
            <Loader color='#2B8ABC' />
          ) : (
            <span
              onClick={handleResend}
              className={cn(
                'text-base w-1/2 text-primary cursor-pointer hidden',
                {
                  'block ':
                    timer === 0 &&
                    (forgotPasswordOtpMutation.status === 'success' ||
                      forgotPasswordOtpMutation.status === 'idle'),
                },
              )}
            >
              {staticText.resendOtp}
            </span>
          )}
          <span className='w-full flex justify-end'>{formatTime(timer)}</span>
        </div>
        <Button
          onClick={handleOtpSubmit}
          className='text-base mt-4'
          disabled={otp.length < 6}
          loading={otpMutation.status === 'pending'}
        >
          {staticText.btnText}
        </Button>
        <Typography className='text-center w-full flex items-center justify-center mt-3'>
          {staticText.backTo}
          &nbsp;
          <a className='cursor-pointer text-primary' href={ROUTES.LOGIN}>
            {staticText.signIn}
          </a>
        </Typography>
      </div>
    </>
  );
};

export default ForgotPasswordOtp;
