import React, { useMemo, useState } from 'react';
import { GoBell } from 'react-icons/go';
import { PiChecksBold } from 'react-icons/pi';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';

import { IPaginationResponse } from '@/@types';
import { INotification } from '@/@types/notification';
import CircularLoader from '@/components/ClipLoader';
import Container from '@/components/Container';
import StateIndicator from '@/components/StateIndicator';
import { Typography } from '@/components/Typography';
import { Button } from '@/components/ui/button';
import {
  NOTIFICATION_TABS,
  NOTIFICATIONS_ACTION,
  NotificationTabsList,
} from '@/constants/notifications';
import { useGenericMutation } from '@/hooks/useMutationData';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import { useAuth } from '@/provider/AuthProvider';
import { useDrawerContext } from '@/provider/DrawerProvider';
import { ROUTES } from '@/routes';
import {
  getNotifications,
  markAllNotificationsRead,
  markNotificationRead,
} from '@/services/notifications';
import {
  InfiniteData,
  useInfiniteQuery,
  useQueryClient,
} from '@tanstack/react-query';

import NotificationItem from './NotificationItem';

const Notifications = () => {
  const staticText = strings.notificationScreen;
  const isSmallScreen = window.innerWidth < 768;

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { isCandidate } = useAuth();
  const { setShowNotifications, unreadNotificationsCount } = useDrawerContext();

  const [activeTab, setActiveTab] = useState(NOTIFICATION_TABS.TAB_ALL);

  const { data, fetchNextPage, hasNextPage, isLoading } = useInfiniteQuery({
    queryKey: ['getNotifications', activeTab, unreadNotificationsCount],
    queryFn: ({ pageParam = 1 }) =>
      getNotifications(activeTab, pageParam.toString()),
    initialPageParam: 1,
    enabled: !!activeTab,
    getNextPageParam: (data, pages) => {
      if (typeof data === 'object' && pages.length < data.totalPages) {
        return pages.length + 1;
      }
      return undefined;
    },
  });

  const markNotificationReadMutation = useGenericMutation<string, boolean>(
    markNotificationRead,
    {
      onSuccess: (response, variables) => {
        if (response) {
          const notificationId = variables;

          queryClient.setQueryData(
            ['getNotifications', activeTab, unreadNotificationsCount],
            (oldData: InfiniteData<IPaginationResponse<INotification>>) => {
              if (!oldData?.pages) return oldData;
              const updatedPages = oldData.pages.map((page) => ({
                ...page,
                results: page.results.map((notification) =>
                  notification._id === notificationId
                    ? { ...notification, seen: true }
                    : notification,
                ),
              }));

              return {
                ...oldData,
                pages: updatedPages,
              };
            },
          );
        }
      },
    },
  );

  const markAllNotificationsReadMutation = useGenericMutation<null, boolean>(
    markAllNotificationsRead,
    {
      onSuccess: (response) => {
        if (response) {
          queryClient.setQueryData(
            ['getNotifications', activeTab, unreadNotificationsCount],
            (oldData: InfiniteData<IPaginationResponse<INotification>>) => {
              if (!oldData?.pages) return oldData;

              const updatedPages = oldData.pages.map((page) => ({
                ...page,
                results: page.results.map((notification) => ({
                  ...notification,
                  seen: true,
                })),
              }));

              return {
                ...oldData,
                pages: updatedPages,
              };
            },
          );
        }
      },
    },
  );

  const handleNotificationClick = (notification: INotification) => {
    const { _id: notificationId, action, message } = notification;
    markNotificationReadMutation.mutate(notificationId);

    if (action === NOTIFICATIONS_ACTION.STATUS_UPDATE) {
      if (isCandidate) {
        // TODO: this function will be updated after api integration
        console.log('message', message);
        const path = ROUTES.DASHBOARD;
        setShowNotifications(false);
        navigate(path);
      }
    }
  };

  const notifications = useMemo(() => {
    return (
      data?.pages.flatMap(
        (page) => (page as IPaginationResponse<INotification>).results,
      ) || []
    );
  }, [data?.pages]);

  const Content = () => {
    if (isLoading) return <StateIndicator state='Loading' />;
    if (!notifications.length)
      return (
        <StateIndicator
          state='Empty'
          noDataMessage={staticText.noNotifications}
        />
      );
    return (
      <div
        id='scrollableDiv'
        className='h-full flex flex-col gap-4 overflow-y-auto'
      >
        <InfiniteScroll
          dataLength={notifications.length}
          next={fetchNextPage}
          hasMore={hasNextPage}
          loader={<CircularLoader />}
          scrollableTarget='scrollableDiv'
        >
          {notifications.map((notification) => (
            <NotificationItem
              key={notification._id}
              notification={notification}
              onClick={() => handleNotificationClick(notification)}
            />
          ))}
        </InfiniteScroll>
      </div>
    );
  };

  return (
    <Container
      className={cn(
        'w-96 p-0 md:p-0 h-[calc(100vh-8rem)] shadow-lg flex flex-col overflow-hidden',
        { 'border-0 h-full w-auto': isSmallScreen },
      )}
    >
      <div className='p-4 flex gap-2 items-center'>
        <GoBell className='text-primaryBlack' />
        <Typography variant='subheading' className='font-bold md:text-sm'>
          {staticText.notifications}
        </Typography>
      </div>
      <>
        <div className='flex items-center gap-6 mx-4 mb-4'>
          {NotificationTabsList.map((tab) => (
            <Button
              key={tab}
              variant={activeTab === tab ? 'default' : 'link'}
              className={cn('p-0 text-sm w-auto rounded-lg capitalize h-8', {
                'bg-primary p-2': activeTab === tab,
                'text-mouseGrey no-underline': activeTab !== tab,
              })}
              onClick={() => setActiveTab(tab as NOTIFICATION_TABS)}
            >
              {tab}
            </Button>
          ))}
          <Button
            variant='link'
            className='px-0 w-auto ml-auto h-8 no-underline'
            textWithIconClassName='px-0'
            onClick={() => markAllNotificationsReadMutation.mutate(null)}
            icon={<PiChecksBold />}
            iconPosition='left'
          >
            {staticText.markAllRead}
          </Button>
        </div>
        {Content()}
      </>
    </Container>
  );
};

export default Notifications;
