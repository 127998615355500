import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';

import { cn } from '@/lib/utils';
import { useDrawerContext } from '@/provider/DrawerProvider';

import OptionText, { IOptionText } from './OptionText';

interface IDrawerOption extends IOptionText {
  location: string;
  icon: ReactElement;
  selected?: boolean;
  name: string;
}

const DrawerOption = ({ selected, name, icon, location }: IDrawerOption) => {
  const { setCurrentTab, setDrawerExpand, isSmallScreen } = useDrawerContext();

  const handleOnClick = () => {
    setCurrentTab(name);
    if (isSmallScreen) setDrawerExpand(false);
  };

  return (
    <Link
      to={location}
      onClick={handleOnClick}
      className={cn(
        'flex min-w-fit items-center py-2 px-3 rounded-lg my-1 justify-between',
        {
          'bg-primary text-white font-bold rounded-xl': selected,
        },
      )}
    >
      <div className='flex items-center gap-3'>
        <div className='w-5'>
          {React.cloneElement(icon, {
            color: selected ? 'white' : '#1B1A1B',
          })}
        </div>
        <OptionText name={name} selected={selected} />
      </div>
    </Link>
  );
};

export default DrawerOption;
