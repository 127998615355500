import React from 'react';

import { Typography } from '@/components/Typography';
import { Separator } from '@/components/ui/seperator';
import { profileSections } from '@/constants/dashboard';

const ProfileSections = () => {
  return (
    <div className='mt-6 bg-blue h-auto p-2 lg:h-fit lg:py-2 mx-auto flex flex-wrap  rounded-md '>
      {profileSections.map((item, index, arr) => (
        <React.Fragment key={index}>
          <Typography className='h-full mx-1 sm:mx-auto px-1 text-white'>
            {item}
          </Typography>
          {index < arr.length - 1 && (
            <Separator orientation='vertical' className='h-5' />
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default ProfileSections;
