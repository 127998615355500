import {
  IAcademicHistory,
  IFinalStep,
  IPersonalInfo,
  ISpecialSkills,
  ITrainingSeminar,
  IWorkExperiences,
} from '@/@types/profile';

export type setDataType =
  | { step: 1; data: IPersonalInfo }
  | { step: 2; data: IAcademicHistory }
  | { step: 3; data: ITrainingSeminar }
  | { step: 4; data: IWorkExperiences }
  | { step: 5; data: ISpecialSkills }
  | { step: 6; data: IFinalStep };

export type FormState = {
  currentStep: number;
  handleNext: () => void;
  handleBack: () => void;
  handleSkip: (stepNumber: number) => void;
  skippedSteps: number[];
  stepOne: IPersonalInfo | null;
  stepTwo: IAcademicHistory | null;
  stepThree: ITrainingSeminar | null;
  stepFour: IWorkExperiences | null;
  stepFive: ISpecialSkills | null;
  stepSix: IFinalStep | null;
  setData: ({ step, data }: setDataType) => void;
};

const personalInfo: IPersonalInfo = {
  avatar: null,
  resume: null,
  positionDesired: '',
  expectedSalary: '',
  name: '',
  nickname: '',
  email: '',
  contactNo: '',
  presentAddress: '',
  civilStatus: '',
  permanentAddress: '',
  sex: '',
  birthPlace: '',
  birthDate: '',
  age: 0,
  nationality: '',
  height: '',
  weight: '',
  religion: '',
  sssNo: '',
  tin: '',
  philHealthNo: '',
  hdmfNo: '',
  nameOfSpouse: '',
  occupationOfSpouse: '',
  nameOfMother: '',
  occupationOfMother: '',
  nameOfFather: '',
  occupationOfFather: '',
  distinguishMarksFeatures: '',
};

const academicHistory: IAcademicHistory = {
  college: '',
  collegeYearCovered: '',
  collegeCourse: '',
  vocational: '',
  vocationalYearCovered: '',
  vocationalCourse: '',
  highSchool: '',
  highSchoolYearCovered: '',
  highSchoolCourse: '',
};

const trainingSeminar: ITrainingSeminar = {
  trainingSeminar: [
    {
      courseTitle: '',
      inclusiveDates: '',
      venue: '',
    },
    {
      courseTitle: '',
      inclusiveDates: '',
      venue: '',
    },
    {
      courseTitle: '',
      inclusiveDates: '',
      venue: '',
    },
  ],
};

const workExperiences: IWorkExperiences = {
  workExperience: [
    {
      nameOfCompany: '',
      position: '',
      salary: 0,
      startDate: '',
      endDate: '',
      reasonForLeaving: '',
    },
  ],
};

const specialSkills: ISpecialSkills = {
  specialSkills: '',
};

const finalStep: IFinalStep = {
  relation: [
    {
      name: '',
      relationship: '',
    },
  ],
  chargedCourt: '',
  emergency: [
    {
      name: '',
      relationship: '',
      address: '',
      telNo: '',
    },
  ],
};

export const DEFAULT_STATE: FormState = {
  currentStep: 0,
  handleNext: () => {},
  handleBack: () => {},
  handleSkip: () => {},
  skippedSteps: [],
  stepOne: personalInfo,
  stepTwo: academicHistory,
  stepThree: trainingSeminar,
  stepFour: workExperiences,
  stepFive: specialSkills,
  stepSix: finalStep,
  setData: () => {},
};
