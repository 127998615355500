import * as React from 'react';
import { FieldErrors } from 'react-hook-form';

import { ErrorMessage as HookFormErrorMessage } from '@hookform/error-message';

import { cn } from 'src/lib/utils';

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  errors?: FieldErrors;
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, errors, ...props }, ref) => {
    return (
      <>
        <textarea
          placeholder='Type here'
          className={cn(
            'flex min-h-20 w-full rounded-md border border-slate-200 bg-white px-3 py-2 text-sm ring-offset-white placeholder:text-slate-500 focus-visible:outline-none focus-visible:none disabled:cursor-not-allowed disabled:opacity-50 dark:border-slate-800 dark:bg-slate-950 dark:ring-offset-slate-950 dark:placeholder:text-slate-400 dark:focus-visible:ring-slate-300',
            className,
          )}
          ref={ref}
          {...props}
        />
        {errors && (
          <HookFormErrorMessage
            errors={errors}
            name='jobDescription'
            render={({ message }) => (
              <p className='text-redColor text-xs'>{message}</p>
            )}
          />
        )}
      </>
    );
  },
);
Textarea.displayName = 'Textarea';

export { Textarea };
