export const REST_SUB_URL = {
  LOGIN: '/auth/login',
  FORGOT_PASSWORD: '/auth/forgot-password',
  VERIFY_RESET_PASSWORD: '/auth/verify-reset-password',
  RESET_PASSWORD: '/auth/reset-password',
  PROFILE: '/profile',
  GET_ALL_NOTIFICATIONS: '/notifications',
  MARK_NOTIFICATION_READ: '/notifications/mark-as-read',
  MARK_ALL_NOTIFICATIONS_READ: '/notifications/mark-as-all-read',
  GET_UN_READ_NOTIFICATIONS_COUNT: '/notifications/un-read-count',
  CLEAR_UN_READ_NOTIFICATIONS_COUNT: '/notifications/clear-read-count',
};
