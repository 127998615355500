import { IoDocumentOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

import LogoutIcon from '@/assets/svgs/LogoutIcon';
import ProfileIcon from '@/assets/svgs/ProfileIcon';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import { useAuth } from '@/provider/AuthProvider';
import { useDrawerContext } from '@/provider/DrawerProvider';
import { ROUTES } from '@/routes';
import { clearStorage } from '@/utils/localstorage';

import { Button } from '../ui/button';

import OptionText from './DrawerOption/OptionText';
import DrawerOption from './DrawerOption';

const DrawerFooter = () => {
  const staticText = strings.sidebarRoutes;
  const { currentTab, drawerExpand } = useDrawerContext();

  const { setUser, setToken } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    clearStorage();
    setUser(null);
    setToken(null);
    navigate(ROUTES.LOGIN);
  };

  return (
    <div
      className={cn(' flex flex-col w-44 mx-3', {
        'items-center w-12': !drawerExpand,
      })}
    >
      <DrawerOption
        selected={currentTab === staticText.profile}
        name={staticText.profile}
        location={ROUTES.PROFILE}
        icon={<ProfileIcon />}
      />
      <DrawerOption
        selected={currentTab === staticText.mydocuments}
        name={staticText.mydocuments}
        location={ROUTES.MY_DOCUMENTS}
        icon={<IoDocumentOutline />}
      />
      <Button
        variant='link'
        onClick={handleLogout}
        className='flex items-center py-2 px-3 justify-between no-underline'
      >
        <div className='flex items-center gap-3'>
          <div className='w-5'>
            <LogoutIcon />
          </div>
          <OptionText name={staticText.logout} className='text-tomatoRed' />
        </div>
      </Button>
    </div>
  );
};

export default DrawerFooter;
