import DrawerContainer from './DrawerContainer';
import DrawerFooter from './DrawerFooter';
import DrawerHeader from './DrawerHeader';

const Drawer = () => {
  return (
    <DrawerContainer>
      <DrawerHeader />
      <div className='absolute -mt-[1px] h-[1px] w-full border-b-greyWhite border-b top-20' />
      <div className='w-full h-full flex flex-col'>
        <DrawerFooter />
      </div>
    </DrawerContainer>
  );
};

export default Drawer;
