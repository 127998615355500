import { RegisterOptions } from 'react-hook-form';

import { EnumType, INameable, variantType } from '@/@types';
import { ISetNewPasswordFields } from '@/@types/auth';
import { INPUT_ERRORS } from '@/constants';

export const EmailRegex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/;

export const generateInitials = (name: string) => {
  const nameArray = name?.split(' ');
  const initials = `${nameArray?.[0]?.[0] || ''}${nameArray?.[1]?.[0] || ''}`;
  return initials.toUpperCase();
};

export const generateHexCode = (name: string) => {
  const asciiSum = name
    ?.split('')
    ?.reduce((sum, char) => sum + char.charCodeAt(0), 0);

  const adjustColor = (value: number) => {
    return ((value % 256) + 256) % 256;
  };

  const red = adjustColor(asciiSum || 0);
  const green = adjustColor((asciiSum || 0) * 2);
  const blue = adjustColor((asciiSum || 0) * 3);

  const hexCode = [red, green, blue]
    .map((value) => value.toString(16).padStart(2, '0'))
    .join('');

  return `#${hexCode.toUpperCase()}`;
};

const calculateLuminance = (r: number, g: number, b: number) => {
  const a = [r, g, b].map((value) => {
    const v = value / 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });

  return a[0]! * 0.2126 + a[1]! * 0.7152 + a[2]! * 0.0722;
};

// Function to get the text color (black or white) based on the background color
export const getTextColor = (hex: string) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  const luminance = calculateLuminance(r, g, b);

  // Use white text for dark backgrounds, black text for light backgrounds
  return luminance < 0.5 ? '#FFFFFF' : '#000000';
};

export const snakeToTitleCase = (input?: string) => {
  if (!input) return;
  return input.replace(/[_-]/g, ' ');
};

export const validatePassword = (value: string) => {
  if (!/[a-z]/.test(value)) return INPUT_ERRORS.PASSWORD.lowercase;
  if (!/[A-Z]/.test(value)) return INPUT_ERRORS.PASSWORD.uppercase;
  if (!/\d/.test(value)) return INPUT_ERRORS.PASSWORD.number;
  if (!/[!@#$%^&*(),.?":{}|<>]/.test(value))
    return INPUT_ERRORS.PASSWORD.specialChar;
  return true;
};

export const formatEmailAddress = (email: string) => {
  if (!email) return '';
  const [localPart, domain] = email.split('@');
  if (!localPart || !domain) return '';
  const maskedLocalPart = `${localPart.charAt(0)}**`;
  const [domainName, topLevelDomain] = domain.split('.');
  if (!domainName || !topLevelDomain) return '';
  const maskedDomain = `@***${domainName.slice(-2)}.${topLevelDomain}`;
  return maskedLocalPart + maskedDomain;
};

export const validateNewPasswordFields = (newPassword?: string) => {
  return {
    newPasswordRules: {
      required: INPUT_ERRORS.PASSWORD.required,
      validate: (value: string) => {
        if (value.length < 8) {
          return INPUT_ERRORS.PASSWORD.minLength;
        }
        return validatePassword(value);
      },
    } as RegisterOptions<ISetNewPasswordFields, 'password'>,

    confirmNewPasswordRules: {
      required: INPUT_ERRORS.CONFIRM_PASSWORD.required,
      validate: (value: string) => {
        if (newPassword && !value) {
          return INPUT_ERRORS.CONFIRM_PASSWORD.required;
        }
        if (newPassword !== value) {
          return INPUT_ERRORS.CONFIRM_PASSWORD.match;
        }
        return true;
      },
    } as RegisterOptions<ISetNewPasswordFields, 'reEnterPassword'>,
  };
};

export const GetFormattedName = <T extends INameable>(data?: T): string => {
  if (!data) return '';
  const { firstName, lastName } = data;
  return `${firstName} ${lastName}`.trim();
};

export const EnumToArray = <T extends EnumType<T>>(enumObj: T): string[] => {
  return Object.values(enumObj);
};

export const getTagVariant = (text?: string): variantType => {
  if (!text) return 'blue';
  const normalizedText = text.toLowerCase();
  const variantMap = {
    'on hold': 'yellow',
    'shortlisted': 'purple',
    'rejected': 'red',
    'interviewing': 'pink',
    'endorsed': 'blue',
    'active': 'green',
    'in active': 'orange',
    'hired': 'green',
    'need approval': 'yellow',
  } as const;

  for (const key in variantMap) {
    if (normalizedText === key) {
      return variantMap[key as keyof typeof variantMap];
    }
  }

  return 'blue';
};
