import React from 'react';
import { FaCheck } from 'react-icons/fa';

import { Typography } from '@/components/Typography';
import { STEPPER_STEPS } from '@/constants/profile';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import { useFormContext } from '@/provider/FormProvider';

const itemsWidths = 100 / 6;

const Stepper = () => {
  const { common } = strings;
  const { currentStep, skippedSteps } = useFormContext();

  const isStepSkipped = (stepNumber: number) =>
    skippedSteps.includes(stepNumber);

  return (
    <ul className='mt-4 mx-auto w-10/12 flex flex-row'>
      {STEPPER_STEPS.map((step, index) => {
        const stepNumber = index + 1;
        return (
          <li
            key={stepNumber}
            style={{ width: `${itemsWidths * 100}%` }}
            className={cn('relative flex items-center shrink basis-0', {
              'flex-1': stepNumber !== STEPPER_STEPS.length,
              'text-primary': currentStep >= stepNumber,
              'text-gray-600': currentStep < stepNumber,
            })}
          >
            <div className='flex flex-col items-center absolute -top-1.5 -left-5 md:-left-14 lg:-left-16'>
              <div
                className={cn(
                  'flex justify-center items-center rounded-full p-[1px] size-4 border-[3px]',
                  {
                    'bg-primary text-white':
                      currentStep > stepNumber && !isStepSkipped(stepNumber),
                    'bg-gray-100 text-gray-800':
                      currentStep <= stepNumber || isStepSkipped(stepNumber),
                    'border-primary': currentStep >= stepNumber,
                  },
                )}
              >
                {currentStep > stepNumber && !isStepSkipped(stepNumber) ? (
                  <FaCheck className='text-white' />
                ) : null}
              </div>
              <div className='md:w-32 lg:w-36 text-center'>
                <Typography className='text-sm font-medium mt-2'>
                  <span>{common.step}</span> {stepNumber}
                </Typography>
                <Typography className='text-sm font-semibold mt-2 hidden md:block'>
                  {step}
                </Typography>
              </div>
            </div>
            {STEPPER_STEPS.length !== stepNumber && (
              <div
                className={cn('w-full h-[3px]', {
                  'bg-primary': currentStep > stepNumber,
                  'bg-gray-200': currentStep <= stepNumber,
                })}
              />
            )}
          </li>
        );
      })}
    </ul>
  );
};

export default Stepper;
