import React, { useEffect, useRef } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { GoBell, GoBellFill, GoHome, GoHomeFill } from 'react-icons/go';
import { HiDownload } from 'react-icons/hi';
import { IoDocumentOutline } from 'react-icons/io5';
import { PiChatCenteredDots, PiChatCenteredDotsFill } from 'react-icons/pi';
import { RxHamburgerMenu } from 'react-icons/rx';
import { useLocation, useNavigate } from 'react-router-dom';

import { IDropdownOption } from '@/@types';
import LogoutIcon from '@/assets/svgs/LogoutIcon';
import ProfileIcon from '@/assets/svgs/ProfileIcon';
import { HEADER_DROPDOWN_OPTIONS, PROFILE_DROPDOWN_OPTIONS } from '@/constants';
import { useGenericMutation } from '@/hooks/useMutationData';
import { cn } from '@/lib/utils';
import Notifications from '@/pages/Notifications';
import { useAuth } from '@/provider/AuthProvider';
import { useDrawerContext } from '@/provider/DrawerProvider';
import { ROUTES } from '@/routes';
import { clearUnreadNotificationsCount } from '@/services/notifications';
import { snakeToTitleCase } from '@/utils/common';
import { clearStorage } from '@/utils/localstorage';

import Logo from '../assets/images/logo.svg';

import { Button } from './ui/button';
import ReactDropdown from './Dropdown';
import ProfileBadge from './ProfileBadge';

const dropdownOptionsWithIcons: IDropdownOption[] =
  PROFILE_DROPDOWN_OPTIONS.map((option) => {
    switch (option.value) {
      case HEADER_DROPDOWN_OPTIONS.PROFILE:
        return { ...option, icon: <ProfileIcon /> };
      case HEADER_DROPDOWN_OPTIONS.MY_DOCUMENTS:
        return { ...option, icon: <IoDocumentOutline /> };
      case HEADER_DROPDOWN_OPTIONS.LOGOUT:
        return { ...option, icon: <LogoutIcon /> };
      default:
        return option;
    }
  });

const Header = () => {
  const notificationRef = useRef<HTMLDivElement>(null);
  const bellIconRef = useRef<HTMLButtonElement>(null);
  const { user } = useAuth();
  const navigate = useNavigate();
  const {
    setDrawerExpand,
    drawerExpand,
    isSmallScreen,
    showNotifications,
    setShowNotifications,
    unreadNotificationsCount,
    setUnreadNotificationsCount,
  } = useDrawerContext();

  const notificationsVisible =
    (isSmallScreen && location.pathname.includes(ROUTES.NOTIFICATIONS)) ||
    showNotifications;
  const { lastName, firstName, avatar, profileType } = user || {};

  const handleNavigationClick = () => {
    if (unreadNotificationsCount) {
      clearUnreadNotifications.mutate(null);
    }
    const toggleNotifications = () => setShowNotifications((prev) => !prev);

    if (!notificationsVisible) {
      isSmallScreen ? navigate(ROUTES.NOTIFICATIONS) : toggleNotifications();
    } else {
      isSmallScreen ? navigate(-1) : toggleNotifications();
    }
  };

  const clearUnreadNotifications = useGenericMutation<null, boolean>(
    clearUnreadNotificationsCount,
    {
      onSuccess: (response) => {
        if (response) {
          setUnreadNotificationsCount(0);
        }
      },
    },
  );

  const { setUser, setToken } = useAuth();

  const getActiveTab = useLocation();

  const activeTab = (route: string) => {
    return getActiveTab.pathname.includes(route);
  };

  const handleProfileOptionChange = (
    option: IDropdownOption | null | string,
  ) => {
    switch (option) {
      case HEADER_DROPDOWN_OPTIONS.PROFILE:
        navigate(ROUTES.PROFILE);
        break;
      case HEADER_DROPDOWN_OPTIONS.MY_DOCUMENTS:
        navigate(ROUTES.MY_DOCUMENTS);
        break;
      case HEADER_DROPDOWN_OPTIONS.LOGOUT:
        window.location.href = ROUTES.LOGIN;
        clearStorage();
        setUser(null);
        setToken(null);
        break;
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        showNotifications &&
        !notificationRef.current?.contains(event.target as Node) &&
        !bellIconRef.current?.contains(event.target as Node)
      ) {
        handleNavigationClick();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showNotifications]);

  return (
    <div className='relative'>
      <div className='flex justify-between items-center w-full px-4 md:px-8 h-20 bg-white border-b-greyWhite border-b'>
        <div className='flex items-center'>
          <div
            className='w-14 cursor-pointer'
            onClick={() => navigate(ROUTES.DASHBOARD)}
          >
            <img src={Logo} alt='Logo' />
          </div>
          <Button
            variant='ghost'
            size='icon'
            icon={
              <HiDownload className='-rotate-90 text-primaryBlack text-xl' />
            }
            className={cn('size-8 ', {
              hidden: drawerExpand || isSmallScreen,
            })}
            onClick={() => setDrawerExpand((prev) => !prev)}
          />
        </div>
        <div className='flex gap-4 md:gap-5 justify-between items-center'>
          <Button
            variant='outline'
            size='icon'
            icon={
              activeTab(ROUTES.DASHBOARD) ? (
                <GoHomeFill className='text-primary text-xl' />
              ) : (
                <GoHome className='text-primaryBlack text-lg' />
              )
            }
            className='border-greyWhite rounded-[5px] bg-simplyViolet size-8 border'
            onClick={() => navigate(ROUTES.DASHBOARD)}
          />
          <Button
            variant='outline'
            size='icon'
            icon={
              activeTab(ROUTES.CHAT) ? (
                <PiChatCenteredDotsFill className='text-primary text-xl' />
              ) : (
                <PiChatCenteredDots className='text-primaryBlack text-lg' />
              )
            }
            className='border-greyWhite rounded-[5px] bg-simplyViolet size-8 border'
            onClick={() => navigate(ROUTES.CHAT)}
          />
          <div className='flex'>
            <Button
              ref={bellIconRef}
              variant='outline'
              size='icon'
              icon={
                notificationsVisible ? (
                  <GoBellFill className='text-primary text-xl' />
                ) : (
                  <GoBell className='text-primaryBlack text-lg' />
                )
              }
              className='border-greyWhite rounded-[5px] bg-simplyViolet size-8 border'
              onClick={handleNavigationClick}
            />
          </div>
          <RxHamburgerMenu
            className='md:hidden cursor-pointer'
            onClick={() => setDrawerExpand(true)}
          />
          {!isSmallScreen ? (
            <ReactDropdown
              options={dropdownOptionsWithIcons}
              onChange={handleProfileOptionChange}
              className='min-w-[192px]'
              inputHeight='0px'
              inputWidth='0px'
              valueHide={true}
              customButton={
                <Button className='bg-white w-48 flex justify-between text-left'>
                  <ProfileBadge
                    name={`${firstName} ${lastName}`}
                    profilePicture={avatar as string}
                    attribute={snakeToTitleCase(profileType?.toLowerCase())}
                  />
                  <FaChevronDown className='ml-1 text-black ' />
                </Button>
              }
            />
          ) : null}
        </div>
      </div>
      {showNotifications ? (
        <div
          ref={notificationRef}
          className={cn({
            'fixed right-4 top-[70px] z-50': !isSmallScreen,
          })}
        >
          <Notifications />
        </div>
      ) : null}
    </div>
  );
};

export default Header;
