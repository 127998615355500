import React from 'react';

import { INotification } from '@/@types/notification';
import ProfileBadge from '@/components/ProfileBadge';
import Tags from '@/components/Tags';
import { Typography } from '@/components/Typography';
import { NOTIFICATIONS_ACTION } from '@/constants/notifications';
import { cn } from '@/lib/utils';
import { GetFormattedName, getTagVariant } from '@/utils/common';
import { formatTimestamp } from '@/utils/dateTime';

interface NotificationItemProps {
  notification: INotification;
  onClick: () => void;
}

const NotificationItem: React.FC<NotificationItemProps> = ({
  notification,
  onClick,
}) => {
  const isSmallScreen = window.innerWidth < 768;
  const { sender, message, createdAt, seen, action } = notification;

  const isStatusUpdationNotification =
    action === NOTIFICATIONS_ACTION.STATUS_UPDATE;
  const status = isStatusUpdationNotification
    ? message.split(' ')[message.split(' ').length - 1]?.split('.')[0]
    : '';

  return (
    <div
      className={cn('flex px-4 py-3 border-t border-greyWhite cursor-pointer', {
        'bg-iceBlue': !seen,
      })}
      onClick={onClick}
    >
      <div className='mr-3 flex-shrink-0 '>
        <ProfileBadge
          name={GetFormattedName(sender)}
          profilePicture={sender.avatar as string}
          // Hides the last div of profile badge
          className='[&>div:last-child]:hidden'
        />
      </div>
      <div>
        <Typography className='md:text-sm'>
          <span className='font-bold'>{GetFormattedName(sender)}</span>
          <span className='mr-2'> {message}</span>
          {isStatusUpdationNotification ? (
            <Tags
              text={status}
              variant={getTagVariant(status)}
              containerClassName={cn('w-fit inline-block', {
                'h-6': !isSmallScreen,
              })}
            />
          ) : null}
        </Typography>
        {/* TODO: Convert to local time */}
        <Typography className='mt-1 text-xs md:text-xs text-mouseGrey block'>
          {formatTimestamp(createdAt)}
        </Typography>
      </div>
    </div>
  );
};

export default NotificationItem;
