import React, { useState } from 'react';
import { FieldErrors } from 'react-hook-form';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';

import { PASSWORD_VALIDATIONS_POINTS } from '@/constants';
import { strings } from '@/locales';
import { ErrorMessage as HookFormErrorMessage } from '@hookform/error-message';

import { cn } from 'src/lib/utils';

import { Input } from './ui/input';
import TooltipComponent from './Tooltip';
import { Typography } from './Typography';

interface FormFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  title?: string;
  placeholder?: string;
  labelClassName?: string;
  containerClassName?: string;
  name: string;
  value?: string | number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isRequired?: boolean;
  errors?: FieldErrors;
  hideIcon?: boolean;
  hideInfoIcon?: boolean;
  maxLength?: number;
}

const FormField: React.FC<FormFieldProps> = ({
  title,
  placeholder,
  name,
  value,
  labelClassName,
  containerClassName,
  onChange,
  isRequired,
  errors,
  hideIcon = false,
  hideInfoIcon = false,
  type = 'text',
  maxLength = 50,
  ...props
}) => {
  const [hidePassword, setHidePassword] = useState(type === 'password');
  const [inputType, setInputType] = useState(type);

  const togglePasswordVisibility = () => {
    setHidePassword(!hidePassword);
    setInputType((prev) => (prev === 'password' ? 'text' : 'password'));
  };

  return (
    <>
      <label className={cn('flex flex-col ', { 'my-2': title })}>
        <div className='flex justify-between items-center pr-1'>
          {title ? (
            <Typography
              className={cn(
                'flex md:text-sm capitalize font-semibold',
                labelClassName,
              )}
            >
              {title}
              {isRequired ? (
                <span className='text-redColor text-xl ml-1'>*</span>
              ) : null}
            </Typography>
          ) : null}
          {type === 'password' && !hideInfoIcon ? (
            <TooltipComponent>
              <div className='flex flex-col gap-y-0.5'>
                {PASSWORD_VALIDATIONS_POINTS.map((item) => (
                  <Typography
                    key={item}
                    className='flex text-xs md:text-xs gap-1.5'
                  >
                    <IoMdCheckmarkCircleOutline className='text-xs w-4 mt-1 text-greenRevolution' />
                    {item.replace('*', '')}
                  </Typography>
                ))}
              </div>
            </TooltipComponent>
          ) : null}
        </div>
        <div className='relative'>
          <Input
            name={name}
            placeholder={placeholder}
            className={cn(
              'h-12 text-base',
              { 'pr-10': type === 'password' },
              containerClassName,
            )}
            value={value}
            onChange={onChange}
            maxLength={maxLength}
            type={inputType}
            {...props}
          />
          {type === 'password' && !hideIcon ? (
            <button
              onClick={togglePasswordVisibility}
              className='absolute right-4 top-4'
              type='button'
              aria-label={
                hidePassword ? strings.hidePassword : strings.showPassword
              }
            >
              {hidePassword ? <FiEyeOff size={17} /> : <FiEye size={17} />}
            </button>
          ) : null}
        </div>
        <HookFormErrorMessage
          errors={errors}
          name={String(name)}
          render={({ message }) => (
            <p className='text-redColor text-xs'>{message}</p>
          )}
        />
      </label>
    </>
  );
};

export default FormField;
